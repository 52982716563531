<template>
    <div>
        <div class="form-group columns text-right">
            <div class="column">
                <search-box v-model="filterSearch" @change="updateSearch()" />
            </div>
            <div class="column col-auto">
                <div class="form-group text-right">
                    <label class="form-switch form-inline">
                        <input type="checkbox" v-model="filterTree" @change="updateTree()">
                        <i class="form-icon"></i> Показать подразделы
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchBox from '@/components/common/SearchBox';

export default {
    name: 'AccountsFilters',
    props: ['filters'],
    components: {
        SearchBox,
    },

    data() {
        return {
            filterTree: false,
            filterSearch: null,
        };
    },
    created() {
        this.reloadFilters(this.filters || {});
    },

    watch: {
        filters: 'reloadFilters',
    },

    methods: {
        reloadFilters(filters) {
            this.filterTree = filters.tree || false;
            this.filterSearch = filters.search || null;
        },

        updateTree() { this.$emit('set-tree', this.filterTree); },
        updateSearch() { this.$emit('set-search', this.filterSearch); },
    },
};
</script>
