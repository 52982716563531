<template>
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title h5">Настройка отображения булевого значения</div>
            </div>
            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="form-group">
                        <div class="col-3 col-sm-12">
                            <label class="form-label">Выбрано</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <input type="text" v-model="yes"
                                class="form-input" placeholder="Название если выбрано">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-3 col-sm-12">
                            <label class="form-label">Не выбрано</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <input type="text" v-model="no"
                                class="form-input" placeholder="Название если не выбрано">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" :disabled="!canSubmit"
                    type="submit">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    name: 'BooleanConfigModal',

    data() {
        return {
            show: false,
            yes: null,
            no: null,
            complete: null,
        };
    },

    computed: {
        canSubmit() {
            return this.yes !== null && this.yes.length > 0
                && this.no !== null && this.no.length > 0;
        },
    },

    methods: {
        open(options, complete) {
            const opts = options ? JSON.parse(options) : {};
            this.yes = opts.yes || 'Да';
            this.no = opts.no || 'Нет';
            this.complete = complete;
            this.show = true;
        },

        hide() {
            this.show = false;
            this.complete = null;
            this.yes = null;
            this.no = false;
        },

        submit() {
            const options = { yes: this.yes, no: this.no };
            this.complete(JSON.stringify(options));
            this.hide();
        },
    },
};

</script>
