import { mapState, mapActions } from 'vuex';
import * as A from '@/store/scope/tasks/actions';

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';
import SearchBox from '@/components/common/SearchBox';

import TaskItem from '@/components/tasks/TaskItem';

export default {
    name: 'AppTaskList',
    props: ['appID'],
    components: {
        AppLoading, AppNotFound, TaskItem, TablePageSize, TablePagination, SearchBox,
    },

    data() {
        return {
            isLoading: false,
            showTree: false,
            filterSchema: null,
            filterState: null,
            filterSearch: null,
            filterOrder: null,
            errorMsg: null,
        };
    },
    created() {
        this.reloadList();
    },

    watch: {
        appID: 'reloadList',
        tasksFilters: 'reloadFilters',
    },

    computed: {
        ...mapState('scope/tasks', {
            tasksList: 'list',
            tasksPage: 'pages',
            tasksFilters: 'filters',
            tasksAvailable: 'available',
        }),
        isEmpty() { return this.taskList == null || this.taskList.length === 0; },
        hasFilterSchema() {
            return this.tasksAvailable.schema && this.tasksAvailable.schema.length > 0;
        },
        hasFilterState() {
            return this.tasksAvailable.state && this.tasksAvailable.state.length > 0;
        },
    },

    methods: {
        ...mapActions('scope/tasks', {
            fetchTaskList: A.UPDATE_LIST,
            changePage: A.CHANGE_PAGE,
            changeSize: A.CHANGE_SIZE,
            setFilterTree: A.FILTER_TREE,
            setFilterSearch: A.FILTER_SEARCH,
            setFilterSchema: A.FILTER_SCHEMA,
            setFilterState: A.FILTER_STATE,
            setFilterOrder: A.FILTER_ORDER,
        }),

        reloadFilters(filters) {
            this.showTree = filters.tree || false;
            this.filterSchema = filters.schema || null;
            this.filterState = filters.state || null;
            this.filterSearch = filters.search || null;
            this.filterOrder = filters.orderBy || null;
        },

        reloadList() {
            this.isLoading = true;
            this.fetchTaskList({ appID: this.appID }).then(() => {
                this.reloadFilters(this.tasksFilters);
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'tasks' });
            });
        },
    },
};
