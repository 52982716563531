<template>
    <!-- Модальное окно подтверждения удаления -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header text-center">
                <a @click="hide()" class="btn btn-clear float-right"
                    aria-label="Close"></a>
                <div class="modal-title">Подтверждение</div>
            </div>
            <div class="modal-body text-left">
                <div class="content">
                    <slot></slot>
                </div>
            </div>
            <div class="modal-footer text-right">
                <button class="btn btn-primary mx-2" type="button" @click="hide()">Отмена</button>
                <button class="btn btn-error" type="submit">{{action}}</button>
            </div>
        </form>
    </div>

</template>

<script>

export default {
    name: 'RemoveConfirmModal',

    props: {
        action: {
            type: String,
            default: 'Удалить',
        },
    },

    data() {
        return {
            show: false,
        };
    },

    methods: {
        open() { this.show = true; },
        hide() { this.show = false; },
        submit() {
            this.$emit('confirm-remove');
        },
    },
};

</script>
