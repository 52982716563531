import { mapState, mapActions } from 'vuex';
import {
    UPDATE_LIST, CHANGE_PAGE, CHANGE_SIZE,
    FILTER_TREE, FILTER_FINISHED, FILTER_SCHEMA, FILTER_STATE, FILTER_SEARCH,
} from '@/store/scope/processes/actions';

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';
import SearchBox from '@/components/common/SearchBox';

import TaskItem from '@/components/tasks/TaskItem';

export default {
    name: 'AppProcessesList',
    props: ['appID'],
    components: {
        AppLoading, AppNotFound, TaskItem, TablePageSize, TablePagination, SearchBox,
    },

    data() {
        return {
            isLoading: false,
            showTree: false,
            showDeleted: false,
            filterSchema: null,
            filterState: null,
            filterSearch: null,
            errorMsg: null,
        };
    },
    created() {
        this.reloadList();
        this.reloadFilters(this.processesFilters);
    },

    watch: {
        scopeID: 'reloadList',
        processesFilters: 'reloadFilters',
    },

    computed: {
        ...mapState('scope/processes', {
            processesList: 'list',
            processesPage: 'pages',
            processesFilters: 'filters',
            processesAvailable: 'available',
        }),
        isEmpty() { return this.processesList == null || this.processesList.length === 0; },
        hasFilterSchema() {
            return this.processesAvailable.schema && this.processesAvailable.schema.length > 0;
        },
        hasFilterState() {
            return this.processesAvailable.state && this.processesAvailable.state.length > 0;
        },
    },

    methods: {
        ...mapActions('scope/processes', {
            fetchProcessesList: UPDATE_LIST,
            changePage: CHANGE_PAGE,
            changeSize: CHANGE_SIZE,
            setFilterTree: FILTER_TREE,
            setFilterFinished: FILTER_FINISHED,
            setFilterSchema: FILTER_SCHEMA,
            setFilterState: FILTER_STATE,
            setFilterSearch: FILTER_SEARCH,
        }),

        reloadFilters(filters) {
            this.showTree = filters.tree || false;
            this.showFinished = filters.all || false;
            this.filterSchema = filters.schema || null;
            this.filterState = filters.state || null;
            this.filterSearch = filters.search || null;
        },

        reloadList() {
            this.isLoading = true;
            this.fetchProcessesList({ appID: this.appID }).then(() => {
                this.reloadFilters(this.processesFilters);
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'default' });
            });
        },
    },
};
