<template>
    <!-- Модальное окно добавления новой роли -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title text-center h5">Создать роль</div>
            </div>
            <div class="modal-body">
                <div class="content text-left">
                    <div class="form">
                        <div class="form-group" :class="{ 'has-error': errorMsg != null }">
                            <label class="form-label">Название</label>
                            <input class="form-input" type="text"
                                v-model="name" placeholder="Название">
                            <label class="form-switch">
                                <input type="checkbox" v-model="is_types_manager">
                                <i class="form-icon"></i> Редактирование типов и ролей
                            </label>
                            <label class="form-switch">
                                <input type="checkbox" v-model="is_scopes_manager">
                                <i class="form-icon"></i> Редактирование разделов
                            </label>
                            <label class="form-switch">
                                <input type="checkbox" v-model="is_users_manager">
                                <i class="form-icon"></i> Редактирование пользователей
                            </label>
                            <label class="form-switch">
                                <input type="checkbox" v-model="is_schemas_manager">
                                <i class="form-icon"></i> Редактирование схем процессов
                            </label>
                            <label class="form-switch">
                                <input type="checkbox" v-model="is_processes_manager">
                                <i class="form-icon"></i> Редактирование процессов
                            </label>
                            <label class="form-switch">
                                <input type="checkbox" v-model="is_registries_manager">
                                <i class="form-icon"></i> Редактирование реестров
                            </label>
                            <label class="form-switch">
                                <input type="checkbox" v-model="is_process_viewer">
                                <i class="form-icon"></i> Просмотр процессов
                            </label>
                            <p class="form-input-hint">{{errorMsg}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button :disabled="!canSubmit"
                    class="btn btn-primary" type="submit">Создать</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'CreateRoleModal',
    data() {
        return {
            show: false,
            name: '',
            is_scopes_manager: false,
            is_types_manager: false,
            is_users_manager: false,
            is_schemas_manager: false,
            is_processes_manager: false,
            is_registries_manager: false,
            is_process_viewer: false,
            errorMsg: null,
        };
    },
    computed: {
        canSubmit() {
            return this.name.length > 0;
        },
    },

    methods: {
        open() {
            this.show = true;
        },

        hide() {
            this.show = false;
            this.name = '';
            this.is_scopes_manager = false;
            this.is_types_manager = false;
            this.is_users_manager = false;
            this.is_schemas_manager = false;
            this.is_processes_manager = false;
            this.is_registries_manager = false;
            this.is_process_viewer = false;
            this.errorMsg = null;
        },

        showError(msg) {
            this.errorMsg = msg;
        },

        submit() {
            if (this.name.length === 0) {
                this.errorMsg = 'Необходимо указать название роли';
                return;
            }
            this.$emit('create-scope-role', {
                name: this.name,
                scopes: this.is_scopes_manager,
                users: this.is_users_manager,
                types: this.is_types_manager,
                processes: this.is_processes_manager,
                schemas: this.is_schemas_manager,
                registries: this.is_registries_manager,
                pviewer: this.is_process_viewer,
            });
        },
    },
};
</script>
