<template>
    <div class="form-group">
        <div class="py-1">
            <button class="btn form-input text-left"
                type="button" @click="reload()"
                >{{root.name}}</button>
        </div>
        <div :key="idx" v-for="(scope, idx) in scopes" class="py-1">
            <app-sub-scopes-select :root="scope" @select="updateScope(idx, $event)" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
button.form-input {
    padding: 5px 8px;
}
</style>

<script>

import AppSubScopesSelect from './AppSubScopesSelect';

export default {
    name: 'AppScopeSelect',

    components: { AppSubScopesSelect },

    props: {
        root: {
            type: Object,
        },
    },

    data() {
        return {
            scopes: [],
        };
    },

    mounted() {
        this.reload();
    },

    methods: {
        reload() {
            this.scopes = [];
            this.$nextTick(() => {
                this.pushScope(this.root);
            });
        },

        pushScope(scope) {
            const { id, name, typename } = scope;
            this.scopes.push({ id, name, typename });
            this.$emit('select-scope', { id, name, typename });
        },

        updateScope(level, selected) {
            if (level >= this.scopes.length) {
                return;
            }

            const ss = selected;

            if (level < this.scopes.length - 1) {
                if (ss != null && this.scopes[level + 1].id === ss.id) {
                    return;
                }
                this.scopes = this.scopes.slice(0, level + 1);
            }

            if (ss != null) {
                this.pushScope(ss);
            } else {
                const { id, name, typename } = this.scopes[level];
                this.$emit('select-scope', { id, name, typename });
            }
        },
    },
};
</script>
