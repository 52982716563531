<template>
    <div class="panel col-10 col-sm-12">
         <div class="panel-header">
            <div class="panel-title">Создание нового метатипа</div>
        </div>
        <div class="panel-nav">
            <app-type-form action="Создать" @create-type="submit($event)" />
            <div class="has-error">
                <div class="form-input-hint">{{errorMsg}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_METATYPE } from '@/store/scope/types/actions';

import AppTypeForm from './AppTypeForm.vue';

export default {
    name: 'NewMetaType',
    props: ['appID', 'typeID'],
    components: { AppTypeForm },

    data() {
        return {
            errorMsg: null,
        };
    },

    methods: {
        ...mapActions('scope/types', {
            createMetaType: CREATE_METATYPE,
        }),

        submit(payload) {
            this.createMetaType({
                typeID: this.typeID,
                payload,
            }).then(() => {
                this.$router.push({
                    name: 'app-type-info',
                    params: {
                        appID: this.appID,
                        typeID: this.typeID,
                    },
                });
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },
    },
};
</script>
