<template>
    <!-- Модальное окно добавления новой роли пользователю -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title text-center h5">Добавить роль</div>
            </div>
            <div class="modal-body">
                <div class="content form text-left" :class="{ 'has-error': errorMsg != null }">
                    <app-scope-select
                        ref="scopes"
                        :root="root"
                        @select-scope="updateScope($event)"
                    />
                    <div class="form-group">
                        <label class="form-label">Укажите роль</label>
                        <select class="form-select" v-model="role">
                            <option :value="null">-- Выберите роль --</option>
                            <option v-for="role in roles"
                                :key="role.id" :value="role">{{role.name}}</option>
                        </select>
                        <p class="form-input-hint">{{errorMsg}}</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" :disabled="role == null"
                    type="submit">Создать</button>
            </div>
        </form>
    </div>

</template>

<style lang="scss" scoped>
.modal {
    align-items: flex-start;
    .modal-container {
        overflow: visible;
        .modal-body {
            padding: 0 0.8rem;
            overflow: visible;
        }
    }
}
</style>

<script>
import Api from '@/api';

import AppScopeSelect from '@/components/common/AppScopeSelect';

export default {
    name: 'AddUserRoleModal',
    props: {
        root: {
            type: Object,
        },
    },

    components: { AppScopeSelect },

    data() {
        return {
            show: false,
            errorMsg: null,
            scope: null,
            roles: [],
            role: null,
        };
    },

    methods: {
        open() {
            this.errorMsg = null;
            this.scope = this.root;
            this.roles = [];
            this.role = null;
            this.show = true;

            this.$refs.scopes.reload();
            this.reloadRoles();
        },

        hide() {
            this.show = false;
        },

        reloadRoles() {
            this.roles = [];
            this.role = null;
            if (this.scope != null) {
                Api.scopeRoles(this.scope.id).then((resp) => {
                    this.roles = resp.data;
                    this.role = null;
                    this.errorMsg = null;
                }).catch((err) => {
                    this.errorMsg = err.message;
                });
            }
        },

        updateScope(scope) {
            if (this.scope == null || this.scope.id !== scope.id) {
                this.scope = scope;
                this.reloadRoles();
            }
        },

        showError(e) {
            if (e.response) {
                if (e.response.status === 409) {
                    this.errorMsg = 'Пользователю уже предоставлена эта роль';
                } else {
                    this.errorMsg = e.response.data;
                }
            } else {
                this.errorMsg = e.message;
            }
        },

        submit() {
            this.$emit('add-user-role', { scope: this.scope, role: this.role });
        },
    },
};

</script>
