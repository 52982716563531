<template>
    <!-- Модальное окно выбора типа -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title text-center h5"
                    >Выбрать тип разделов для подпроцесса {{schema.name}}</div>
            </div>
            <div class="modal-body content form text-left"
                :class="{ 'has-error': errorMsg != null }">
                <app-scope-select ref="scopes"
                    :root="root"
                    @select-scope="updateScope($event)" />
                <app-type-select ref="types"
                    :listTypes="listTypes"
                    @select-type="updateType($event)" />

                <div v-if="type && type.attrs && type.attrs.length > 0">
                    <label class="form-checkbox">
                        <input type="checkbox" v-model="filter">
                        <i class="form-icon icon"></i>
                        Фильтровать по аттрибуту
                    </label>
                </div>

                <div v-if="filter">
                    <select class="form-select" v-model="filterAttr">
                        <option :value="null" disabled>Укажите аттрибут</option>
                        <option v-for="attr in type.attrs" :key="attr.id" :value="attr"
                            >{{attr.name}}</option>
                    </select>

                    <label v-if="filterAttr != null" class="form-label">Значение</label>
                    <attribute-value ref="filterValue" :attr="filterAttr" />
                </div>

                <p class="form-input-hint">{{errorMsg}}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" :disabled="submitDisabled"
                    type="submit">Выбрать</button>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
.modal {
    align-items: flex-start;
    .modal-container {
        overflow: visible;
        .modal-body {
            padding: 0 0.8rem;
            overflow: visible;
        }
    }
}
</style>

<script>

import AppScopeSelect from '@/components/common/AppScopeSelect';
import AppTypeSelect from '@/components/common/AppTypeSelect';
import AttributeValue from '@/components/common/AttributeValue';

import Api from '@/api';

export default {
    name: 'SelectTypeModal',
    props: {
        root: {
            type: Object,
        },
    },
    components: { AppScopeSelect, AppTypeSelect, AttributeValue },

    data() {
        return {
            show: false,
            form: {},
            schema: {},
            scope: null,
            type: null,
            filter: false,
            filterAttr: null,

            errorMsg: null,
        };
    },

    computed: {
        listTypes() {
            return Api.typeMetaTypes;
        },
        submitDisabled() {
            return this.type === null || this.type.id === null
                || (this.filter && (
                    this.filterAttr == null
                    || !this.$refs.filterValue
                    || !this.$refs.filterValue.hasValue));
        },
    },

    methods: {
        open({ form, schema }) {
            this.form = form;
            this.schema = schema;
            this.scope = this.root;
            this.type = null;
            this.filter = false;
            this.filterAttr = null;
            this.filterValue = null;

            this.errorMsg = null;

            this.show = true;

            this.$refs.scopes.reload();
            this.reloadTypes();
        },

        hide() {
            this.show = false;
        },

        updateScope(scope) {
            if (this.scope == null || this.scope.id !== scope.id) {
                this.scope = scope;
                this.reloadTypes();
            }
        },

        updateType(type) {
            if (this.type == null || this.type.id !== type.id) {
                if (type.id > 0) {
                    Api.type(type.id).then((resp) => {
                        this.type = resp.data;
                        this.filter = false;
                        this.filterAttr = null;
                        this.errorMsg = null;
                    }).catch((err) => {
                        this.errorMsg = err.message;
                    });
                } else {
                    this.type = type;
                    this.filter = false;
                    this.filterAttr = null;
                    this.errorMsg = null;
                }
            }
        },

        reloadTypes() {
            this.type = null;
            Api.scopeTypes(this.scope.id).then((resp) => {
                this.$refs.types.reload({
                    name: this.scope.name,
                    types: resp.data,
                });
                this.filter = false;
                this.filterAttr = null;
                this.filterValue = null;
                this.errorMsg = null;
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        submit() {
            this.$emit('select-type', {
                form: this.form,
                schema: this.schema,
                type: {
                    type: this.type,
                    filterAttr: this.filter ? this.filterAttr : null,
                    filterValue: this.filter ? this.$refs.filterValue.getValue : null,
                },
            });
        },
    },
};
</script>
