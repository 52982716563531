<template>
    <div class="m-2 text-left" >
        <div class="divider text-center" :data-content="form.name"></div>
        <div class="form-group">
            <label class="form-label">Название</label>
            <input class="form-input" type="text"
                v-model="form.name" placeholder="Название процесса">
            <div class="form-horizontal"
                v-if="!form.is_finish && hasItems">
                <div class="form-group">
                    <div class="col-4 col-sm-12">
                        <label class="form-label">Брать название из аттрибута</label>
                    </div>
                    <div class="col-8 col-sm-12">
                        <select class="form-select" v-model="form.custom_name_id"
                            :disabled="!hasItems">
                            <option :value="null">-- Отключено --</option>
                            <option v-for="item in form.items" :key="item.id"
                                :value="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel" v-if="isSubprocess">
            <div class="panel-header text-center text-large text-bold">
                <router-link custom v-slot="{ navigate }" :to="{
                    name: 'app-schema-info',
                    params: { appID: appID, schemaID: form.subprocess.schema.id }
                }" >
                    <div class="panel-title c-hover-hand" @click="navigate">
                        <span v-if="form.subprocess.type === 'ALL'">
                            Запуск во всех указанных разделах процесса
                        </span>
                        <span v-else-if="form.subprocess.type === 'ONE'">
                            Запуск в одном из указанных разделов процесса
                        </span>
                        <span v-else>
                            Запуск в выбранных разделах процесса
                        </span>
                        {{form.subprocess.schema.name}}
                    </div>
                </router-link>
            </div>
            <div class="panel-body">
                <div class="toast my-1" v-for="type in form.subprocess.exec_types" :key="type.id">
                    <button class="btn btn-clear float-right"
                        @click="removeType(form.subprocess.schema, type)"></button>
                    Разделы с типом {{type.type.name}}
                    <div v-if="type.filterAttr != null">
                        и значением аттибута {{type.filterAttr.name}} равным
                        {{type.filterValue}}
                    </div>
                </div>
                <div class="text-right p-2" v-if="!form.is_finish">
                    <button class="btn btn-primary mx-2" @click="addType(form.subprocess.schema)">
                        <i class="icon icon-plus"></i>
                        Тип разделов
                    </button>
                </div>
            </div>
        </div>

        <div v-if="!form.is_finish && hasItems">
            <label class="form-label">Аттрибуты</label>
            <div class="form-group columns container" v-for="item in form.items" :key="item.id">
                <div class="column col-auto text-right">
                    <label class="form-checkbox form-inline">
                        <input type="checkbox" v-model="item.is_report">
                        <i class="form-icon"></i> Отчет
                    </label>
                </div>
                <div class="column">
                    <input class="form-input" type="text"
                        v-model="item.name" placeholder="Имя аттрибута">
                </div>
                <div class="column col-auto text-right">
                    <select class="form-select form-inline" disabled :value="item.type">
                        <option value="string">Строка</option>
                        <option value="text">Текст</option>
                        <option value="number">Число</option>
                        <option value="enum">Перечисление</option>
                        <option value="media">Файлы</option>
                        <option value="label">Примечание</option>
                    </select>
                    <label class="form-checkbox form-inline">
                        <input type="checkbox" disabled v-model="item.is_required">
                        <i class="form-icon"></i> Обязательно
                    </label>
                    <button class="btn btn-primary mx-2"
                        :class="{'tooltip': item.type == 'label' }"
                        :disabled="item.type != 'label'"
                        type="button"
                        data-tooltip="Настройка элемента"
                        @click="openConfigModal(item)">
                        <i class="icon icon-edit"></i>
                    </button>
                    <button class="btn btn-success tooltip"
                        type="button"
                        data-tooltip="Комментарий к элементу"
                        @click="openDescriptionModal(item)">
                        <i class="icon icon-edit"></i>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="!form.is_finish">
            <label class="form-label">Действия</label>
            <div class="form-group columns container"
                v-for="action in form.actions" :key="action.id">
                <div class="column">
                    <input class="form-input" type="text"
                        v-model="action.name" placeholder="Имя действия">
                </div>
            </div>
        </div>

        <div class="text-left">
            <div class="toast my-1" :key="role.id" v-for="role in form.exec_roles">
                <button class="btn btn-clear float-right" @click="removeRole(role)"></button>
                Все пользователи с ролью {{role.role.name}} в разделе
                {{role.scopeOnly ? 'процесса' : role.scope.name}}
            </div>
        </div>
        <div class="text-left">
            <div class="toast my-1" :key="user.id" v-for="user in form.exec_accounts">
                <button class="btn btn-clear float-right" @click="removeUser(user)"></button>
                Пользователь {{user | userFullName}}
            </div>
        </div>

        <div class="text-right p-2" v-if="!form.is_finish">
            <button class="btn btn-primary mx-2" @click="addUser()">
                <i class="icon icon-plus"></i>
                Пользователь
            </button>
            <button class="btn btn-primary" @click="addRole()">
                <i class="icon icon-plus"></i>
                Роль
            </button>
        </div>

        <div :class="{ 'has-error': isNotValid }">
            <p class="form-input-hint"
                v-if="isNotValid">Выбрано недостаточное количество пользователей</p>
        </div>
    </div>
</template>

<style lang="scss">
.form-select.form-inline {
    width: 150px;
    margin-right: 10px;
}
</style>

<script>

export default {
    name: 'SchemaForm',
    props: ['appID', 'form'],
    computed: {
        isNotValid() {
            return !this.form.is_finish && this.form.accounts_count === 0;
        },
        isSubprocess() {
            return this.form.subprocess !== null;
        },
        hasItems() {
            return this.form.items !== null && this.form.items.length > 0;
        },
    },
    methods: {
        addRole() { this.$emit('add-role', this.form); },
        addUser() { this.$emit('add-user', this.form); },
        addType(schema) { this.$emit('add-type', { form: this.form, schema }); },
        removeRole(role) { this.$emit('remove-role', { form: this.form, role }); },
        removeUser(user) { this.$emit('remove-user', { form: this.form, user }); },
        removeType(schema, type) { this.$emit('remove-type', { form: this.form, schema, type }); },
        openDescriptionModal(attr) { this.$emit('edit-description', attr); },
        openConfigModal(attr) { this.$emit('open-config', attr); },
    },
};
</script>
