<template>
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title h5">Настройка маски</div>
            </div>
            <div class="modal-body">
                <div class="content text-left">
                    <span>Маска может состоять из следующих символов<br/>
                    <code>0</code> - любая цифра<br/>
                    <code>a</code> - любая буква<br/>
                    <code>*</code> - любой символ<br/>
                    Остальные символы трактуются как фиксированная часть маски<br/>
                    <code>[]</code> - опциональная группа символов<br/>
                    <code>{}</code> - фиксированная группа символов<br/>
                    Если требуется использовать определяющий символ как фиксированную часть маски
                    то его нужно экранировать \\ (Например \\0).
                    </span>
                    <div class="form-horizontal">
                        <div class="form-group">
                            <label class="form-label">Маска</label>
                            <input class="form-input"
                                type="text" placeholder="Значение маски"
                                :readonly="readOnly"
                                v-model="maskValue">
                            <div v-if="readOnly">
                                <p class="form-input-hint">
                                    Редактирование используемого аттрибута заблокировано
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div v-if="!readOnly">
                    <button class="btn btn-primary" :disabled="!canSubmit"
                        type="submit">Сохранить</button>
                </div>
                <div v-if="readOnly">
                    <button class="btn btn-primary" type="button"
                        @click="hide()">Закрыть</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    name: 'MaskConfigModal',

    data() {
        return {
            show: false,
            maskValue: null,
            readOnly: false,
            complete: null,
        };
    },

    computed: {
        canSubmit() {
            return this.maskValue != null
                && this.maskValue.trim().length > 0
                && !this.readOnly;
        },
    },

    methods: {
        open(mask, readOnly, complete) {
            this.readOnly = readOnly;
            this.maskValue = mask;
            this.complete = complete;
            this.show = true;
        },

        hide() {
            this.show = false;
            this.complete = null;
            this.maskValue = null;
            this.readOnly = false;
        },

        submit() {
            this.complete(this.maskValue.trim());
            this.hide();
        },
    },
};

</script>
