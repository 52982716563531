<template>
    <div class="panel col-10 col-sm-12">
         <div class="panel-header">
            <div class="panel-title h3">Информация о реестре</div>
        </div>
        <error-msg :error="error" />
        <app-loading v-if="isLoading" />
        <div v-else-if="registry" class="panel-nav">
            <registry-form :registry="registry" v-slot="form">
                <div class="form-group columns">
                    <div class="column">
                        <button class="btn btn-primary" type="button"
                            :disabled="!form.isValid || !form.isChanged"
                            @click="submitUpdate(form.payload)"
                            >Обновить</button>
                    </div>
                    <div class="column col-ml-auto text-right">
                        <button class="btn btn-error" type="button"
                            :disabled="!registry.canRemove"
                            :class="{tooltip: !registry.canRemove}"
                            data-tooltip="Для удаления реестра нужно удалить
    все строкив в данном реестре"
                            @click="$refs.removeModal.open()"
                            >Удалить реестр</button>
                    </div>
                </div>
            </registry-form>
            <remove-confirm-modal ref="removeModal" @confirm-remove="submitRemove()">
                <div class="h5">Вы действительно хотите удалить реестр
                    {{registry.name}}?</div>
            </remove-confirm-modal>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { SELECT_REGISTRY, UPDATE_REGISTRY, REMOVE_REGISTRY } from '@/store/scope/types/registries/actions';

import ErrorMsg from '@/components/common/ErrorMsg';
import AppLoading from '@/components/common/AppLoading';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal';

import RegistryForm from './RegistryForm';

export default {
    name: 'RegistryView',
    props: ['appID', 'typeID', 'registryID'],
    components: {
        AppLoading,
        ErrorMsg,
        RegistryForm,
        RemoveConfirmModal,
    },

    data() {
        return {
            isLoading: true,
            error: null,
        };
    },

    computed: {
        ...mapState('scope/types/registries', { registry: 'selected' }),
    },

    created() {
        this.reload();
    },

    watch: {
        registryID: 'reload',
    },

    methods: {
        ...mapActions('scope/types/registries', {
            fetchRegistry: SELECT_REGISTRY,
            updateRegistry: UPDATE_REGISTRY,
            removeRegistry: REMOVE_REGISTRY,
        }),

        reload() {
            this.isLoading = true;
            const { typeID, registryID } = this;
            this.fetchRegistry({ typeID, registryID }).then(() => {
                this.error = null;
                this.isLoading = false;
            }).catch((err) => {
                this.error = err;
                this.isLoading = false;
            });
        },

        submitUpdate(payload) {
            const { typeID, registryID } = this;
            this.updateRegistry({ typeID, registryID, payload }).then(() => {
                this.error = null;
            }).catch((err) => {
                this.error = err;
            });
        },

        submitRemove() {
            const { appID, typeID, registryID } = this;
            this.removeRegistry({ typeID, registryID }).then(() => {
                this.$router.push({ name: 'app-type-info', params: { appID, typeID } });
            }).catch((err) => {
                this.error = err;
            });
        },
    },
};
</script>
