<template>
    <!-- Модальное окно выбора пользователя системы -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()" v-if="profile">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title text-center h5">Выбор раздела</div>
            </div>
            <div class="modal-body">
                <div class="content form text-left">
                    <app-scope-select
                        :root="profile.scope"
                        @select-scope="update($event)"
                    />
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary mx-2" type="button" @click="hide()">Закрыть</button>
                <button class="btn btn-error"
                    v-if="selected !== null" type="submit">Переместить</button>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
.modal {
    align-items: flex-start;
    .modal-container {
        overflow: visible;
        .modal-body {
            padding: 0 0.8rem;
            overflow: visible;
        }
    }
}
</style>


<script>
import { mapState } from 'vuex';

import AppScopeSelect from '@/components/common/AppScopeSelect';

export default {
    name: 'SelectScopeModal',
    components: { AppScopeSelect },

    data() {
        return {
            show: false,
            selected: null,
        };
    },

    computed: {
        ...mapState('profile', ['profile']),
    },

    methods: {
        open() {
            this.show = true;
        },

        hide() {
            this.show = false;
        },

        update(scope) {
            this.selected = scope;
        },

        submit() {
            this.$emit('select-scope', { scopeID: this.selected.id });
        },
    },
};
</script>
