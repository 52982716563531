import _debounce from 'lodash/debounce';

import Api from '@/api';

import EnumConfigModal from './common/EnumConfigModal';
import MaskConfigModal from './common/MaskConfigModal';
import DefaultValueModal from './common/DefaultValueModal';

function cloneType(type) {
    return {
        code: type != null ? type.code : null,
        name: type != null ? type.name : null,
        attrs: type != null ? type.attrs.map(a => Object.assign({}, a)) : [],
    };
}

function equalType(one, two) {
    if (one.name !== two.name
        || one.code !== two.code
        || one.attrs.length !== two.attrs.length) {
        return false;
    }

    for (let i = 0; i < one.attrs.length; i += 1) {
        const a = one.attrs[i];
        const b = two.attrs[i];
        if (a.id !== b.id
            || a.name !== b.name
            || a.type !== b.type
            || a.is_required !== b.is_required
            || a.order !== b.order
            || a.options !== b.options) {
            return false;
        }
    }
    return true;
}
const TYPE_CODE = 'info.code';

export default {
    name: 'AppTypeForm',
    props: {
        action: {
            type: String,
            default: 'Создать',
        },
        type: {
            type: Object,
            default: null,
        },
    },

    components: {
        EnumConfigModal,
        MaskConfigModal,
        DefaultValueModal,
    },

    data() {
        return {
            info: {
                code: null,
                name: null,
                attrs: [],
            },
            checking: null,
            checkMsg: null,
            errorMsg: null,
        };
    },

    computed: {
        canSubmit() {
            if (this.info.name == null || this.info.name.length === 0) {
                return false;
            }
            return (this.type == null) ? this.checking === 'good' : !equalType(this.type, this.info);
        },

        isEdit() { return this.type != null; },
    },

    created() {
        this.debounceCheckCode = _debounce(this.checkCode, 200);
        this.info = cloneType(this.type);
    },

    watch: {
        [TYPE_CODE](code) {
            if (this.type == null) {
                this.checking = 'load';
                this.checkMsg = null;
                this.debounceCheckCode(code);
            }
        },
        type(type) {
            this.info = cloneType(type);
        },
    },

    methods: {
        swapAttribute(idx) {
            if (idx > 0) {
                const a = this.info.attrs[idx - 1];
                this.info.attrs.splice(idx - 1, 1);
                this.info.attrs.splice(idx, 0, a);
            }
        },

        addAttribute() {
            this.info.attrs.push({
                name: '',
                type: 'string',
                is_required: false,
                options: null,
            });
        },

        delAttribute(idx) {
            this.info.attrs.splice(idx, 1);
        },

        optionsDisabled(attr) {
            return !((attr.type === 'enum') || (attr.type === 'masked'));
        },

        checkCode(code) {
            if (!code) {
                this.checkMsg = 'Код типа не может быть пустым';
                this.checking = 'wrong';
                return;
            }
            Api.validateScopeTypeCode(code).then((resp) => {
                this.checkMsg = null;
                this.checking = 'good';
                this.info.code = resp.data;
            }).catch((e) => {
                if (e.response && e.response.status === 400) {
                    this.checkMsg = 'Код типа содержит недопустимые символы либо слишком короткий';
                } else if (e.response && e.response.status === 409) {
                    this.checkMsg = 'Данный код типа уже используется в системе';
                } else {
                    this.checkMsg = e.message;
                }
                this.checking = 'wrong';
            });
        },

        submit() {
            this.errorMsg = null;
            for (let i = 0; i < this.info.attrs.length; i += 1) {
                const a = this.info.attrs[i];
                if (a.name == null || a.name.length < 1) {
                    this.errorMsg = 'Имя аттрибута не может быть пустым';
                    return;
                }
                if (a.type === 'enum') {
                    let ok;
                    try {
                        ok = JSON.parse(a.options).length > 0;
                    } catch {
                        ok = false;
                    }
                    if (!ok) {
                        this.errorMsg = 'Для аттрибута с типом перечисления необходимо задать минимум одно значение';
                        return;
                    }
                }
                if (a.type === 'masked' && (a.options == null || a.options.length < 1)) {
                    this.errorMsg = 'Для аттрибута с типом маска необходимо задать значение маски';
                    return;
                }
            }

            const attrs = this.info.attrs.map((v, idx) => ({
                id: v.id,
                name: v.name,
                type: v.type,
                order: idx,
                is_required: v.is_required,
                set_required: v.set_required,
                options: v.options,
            }));

            this.$emit('create-type', {
                name: this.info.name,
                code: this.info.code,
                attrs,
            });
        },

        removeType() {
            this.$emit('remove-type');
        },

        openConfigModal(attr) {
            let configModal = null;
            if (attr.type === 'enum') {
                configModal = this.$refs.enumConfigModal;
            }
            if (attr.type === 'masked') {
                configModal = this.$refs.maskConfigModal;
            }
            if (!configModal) {
                return;
            }

            // Если редактирование существующего аттрибута - то подгружаем список текущих значений
            if (this.type !== null && attr.id > 0) {
                Api.typeAttributeValues(this.type.id, attr.id).then((resp) => {
                    this.errorMsg = null;
                    configModal.open(attr, resp.data);
                }).catch((err) => {
                    this.errorMsg = err.message;
                });
            } else { // Иначе просто открываем с пустым списком
                configModal.open(attr, []);
            }
        },

        makeAttrNotRequired(attr) {
            for (let i = 0; i < this.info.attrs.length; i += 1) {
                if (this.info.attrs[i] === attr) {
                    this.info.attrs[i].is_required = false;
                    delete this.info.attrs[i].set_required;
                }
            }
        },

        makeAttrRequired(attr) {
            const needDefault = this.type != null
                && this.type.attrs.filter(a => a.id === attr.id && a.is_required).length === 0;

            if (needDefault) {
                this.$refs.defValueModal.open(attr);
                return;
            }

            for (let i = 0; i < this.info.attrs.length; i += 1) {
                if (this.info.attrs[i] === attr) {
                    this.info.attrs[i].is_required = true;
                    delete this.info.attrs[i].set_required;
                }
            }
        },

        makeAttrRequiredValue({ attr, value }) {
            for (let i = 0; i < this.info.attrs.length; i += 1) {
                if (this.info.attrs[i] === attr) {
                    this.info.attrs[i].is_required = true;
                    this.info.attrs[i].set_required = value;
                }
            }
            this.$refs.defValueModal.hide();
        },
    },
};
