<template>
    <div>
        <app-loading v-if="isLoading" />
        <div v-else class="col-12">
            <accounts-filters  :filters="usersFilters"
                @set-tree="setFilterTree($event)"
                @set-search="setFilterSearch($event)"
                />

            <app-not-found v-if="isEmpty">Не найдено ни одного пользователя</app-not-found>

            <div v-else class="text-left">
                <div :key="item.id" v-for="item in usersList">
                    <router-link custom v-slot="{ navigate }" :to="{
                        name: 'app-user-info',
                        params: { scopeID: item.scopeID, userID: item.id }
                    }" >
                        <div @click="navigate">
                            <app-user-ref class="c-hover-hand"
                                :user="item" :lastTouch="lastTouch">
                            </app-user-ref>
                        </div>
                    </router-link>
                </div>
                <table-pagination
                    :page="usersPage.page" :last="usersPage.count"
                    @changed="changePage($event)"/>
            </div>

            <div class="columns p-2">
                <div class="col-mr-auto text-left">
                    <router-link custom v-slot="{ navigate }"
                        :to="{name: 'app-new-user', params: { appID: this.appID }}">
                        <button class="mx-2 btn btn-primary"
                            type="button" @click="navigate">
                            <i class="icon icon-plus"></i>
                            Создать пользователя
                        </button>
                    </router-link>
                    <router-link custom v-slot="{ navigate }" v-if="hasExtSystems"
                        :to="{name: 'app-new-extuser', params: { appID: this.appID }}">
                        <button class="btn btn-primary"
                            type="button" @click="navigate">
                            <i class="icon icon-plus"></i>
                            Добавить внешнего пользователя
                        </button>
                    </router-link>
                </div>
                <table-page-size class="col-ml-auto" :size="usersPage.size"
                    :total="usersPage.total" @changed="changeSize($event)"
                >пользователей</table-page-size>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import {
    UPDATE_LIST, CHANGE_PAGE, CHANGE_SIZE, FILTER_TREE, FILTER_SEARCH,
} from '@/store/scope/users/actions';

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';

import AppUserRef from './AppUserRef';
import AccountsFilters from './AccountsFilters';

export default {
    name: 'AccountsList',
    props: ['appID'],
    components: {
        AppLoading, AppNotFound, AppUserRef, TablePageSize, TablePagination, AccountsFilters,
    },

    data() {
        return {
            isLoading: false,
        };
    },
    created() {
        this.reloadList();
    },

    watch: {
        appID: 'reloadList',
        usersFilters: 'reloadFilters',
    },

    computed: {
        ...mapState('scope/users', {
            usersList: 'list',
            usersPage: 'pages',
            usersFilters: 'filters',
            lastTouch: 'lastTouch',
        }),
        ...mapState(['app']),
        isEmpty() { return this.usersList == null || this.usersList.length === 0; },
        hasExtSystems() { return this.app.authList.length > 0; },
    },

    methods: {
        ...mapActions('scope/users', {
            reloadUsers: UPDATE_LIST,
            changePage: CHANGE_PAGE,
            changeSize: CHANGE_SIZE,
            setFilterTree: FILTER_TREE,
            setFilterSearch: FILTER_SEARCH,
        }),

        reloadList() {
            this.isLoading = true;
            this.reloadUsers({ appID: this.appID }).then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'tasks' });
            });
        },
    },
};
</script>
