import _debounce from 'lodash/debounce';

import { mapActions, mapState } from 'vuex';

import { CREATE_EXTUSER } from '@/store/scope/users/actions';

import Api from '@/api';

const userInit = {
    system: null,
    username: null,
};
const WATCH_USERNAME = 'user.username';
const WATCH_SYSTEM = 'user.system';
const WATCH_LIST = 'app.authList';

export default {
    name: 'CreateExtUser',
    props: ['appID'],
    data() {
        return {
            errorMsg: null,
            checking: null,
            checkMsg: null,
            user: { ...userInit },
        };
    },
    computed: {
        ...mapState(['app']),
        canSubmit() {
            return this.checking === 'good';
        },
    },

    watch: {
        [WATCH_USERNAME](username) {
            this.checkMsg = null;
            this.checking = 'load';
            this.debouncedCheckUsername(this.user.system, username);
        },
        [WATCH_SYSTEM](system) {
            this.checkMsg = null;
            this.checking = 'load';
            this.debouncedCheckUsername(system, this.user.username);
        },
        [WATCH_LIST](list) {
            this.user.system = list[0].code;
        },
    },

    created() {
        this.debouncedCheckUsername = _debounce(this.checkUsername, 200);
    },

    methods: {
        ...mapActions('scope/users', {
            createExtUser: CREATE_EXTUSER,
        }),

        checkUsername(system, username) {
            if (!username) {
                this.checkMsg = 'Логин пользователя не может быть пустым';
                this.checking = 'wrong';
                return;
            }
            const extUsername = `${username}@${system}`;
            Api.validateUsername(extUsername).then(() => {
                this.checkMsg = null;
                this.checking = 'good';
            }).catch((e) => {
                if (e.response && e.response.status === 409) {
                    this.checkMsg = 'Данный логин уже зарегестрирован в системе';
                } else if (e.response && e.response.status === 400) {
                    if (extUsername.length < 3) {
                        this.checkMsg = 'Логин содержит слишком мало символов';
                    } else {
                        this.checkMsg = 'Логин содержит недопустимые символы';
                    }
                } else {
                    this.checkMsg = e.message;
                }
                this.checking = 'wrong';
            });
        },

        submitCreateUser() {
            if (!this.user.username || this.checking !== 'good') {
                this.errorMsg = 'Необходимо ввести валидный логин пользователя';
                return;
            }

            const username = `${this.user.username}@${this.user.system}`;
            const payload = {
                system: this.user.system,
                username,
            };

            this.createExtUser({ payload, appID: this.appID }).then((user) => {
                this.$router.push({ name: 'app-user-info', params: { appID: this.appID, userID: user.id } });
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },
    },
};
