var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('app-loading'):_c('div',{staticClass:"col-12"},[_c('accounts-filters',{attrs:{"filters":_vm.usersFilters},on:{"set-tree":function($event){return _vm.setFilterTree($event)},"set-search":function($event){return _vm.setFilterSearch($event)}}}),(_vm.isEmpty)?_c('app-not-found',[_vm._v("Не найдено ни одного пользователя")]):_c('div',{staticClass:"text-left"},[_vm._l((_vm.usersList),function(item){return _c('div',{key:item.id},[_c('router-link',{attrs:{"custom":"","to":{
                    name: 'app-user-info',
                    params: { scopeID: item.scopeID, userID: item.id }
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var navigate = ref.navigate;
return [_c('div',{on:{"click":navigate}},[_c('app-user-ref',{staticClass:"c-hover-hand",attrs:{"user":item,"lastTouch":_vm.lastTouch}})],1)]}}],null,true)})],1)}),_c('table-pagination',{attrs:{"page":_vm.usersPage.page,"last":_vm.usersPage.count},on:{"changed":function($event){return _vm.changePage($event)}}})],2),_c('div',{staticClass:"columns p-2"},[_c('div',{staticClass:"col-mr-auto text-left"},[_c('router-link',{attrs:{"custom":"","to":{name: 'app-new-user', params: { appID: this.appID }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var navigate = ref.navigate;
return [_c('button',{staticClass:"mx-2 btn btn-primary",attrs:{"type":"button"},on:{"click":navigate}},[_c('i',{staticClass:"icon icon-plus"}),_vm._v(" Создать пользователя ")])]}}])}),(_vm.hasExtSystems)?_c('router-link',{attrs:{"custom":"","to":{name: 'app-new-extuser', params: { appID: this.appID }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var navigate = ref.navigate;
return [_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":navigate}},[_c('i',{staticClass:"icon icon-plus"}),_vm._v(" Добавить внешнего пользователя ")])]}}],null,false,2828701780)}):_vm._e()],1),_c('table-page-size',{staticClass:"col-ml-auto",attrs:{"size":_vm.usersPage.size,"total":_vm.usersPage.total},on:{"changed":function($event){return _vm.changeSize($event)}}},[_vm._v("пользователей")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }