import { mapState, mapGetters, mapActions } from 'vuex';
import * as ACTIONS from '@/store/scope/users/actions';

import AppLoading from '@/components/common/AppLoading';
import UploadButton from '@/components/common/UploadButton';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal';

import UserProfileForm from '@/components/user/UserProfileForm';
import UserAvatarForm from '@/components/user/UserAvatarForm';
import ChangePasswordModal from '@/components/user/ChangePasswordModal';

import AppUserRoleRef from './AppUserRoleRef';
import AddUserRoleModal from './AddUserRoleModal';
import SelectScopeModal from './SelectScopeModal';

export default {
    name: 'AppUserInfo',
    props: ['appID', 'userID'],

    components: {
        AppLoading,
        UserProfileForm,
        UserAvatarForm,
        UploadButton,
        ChangePasswordModal,
        RemoveConfirmModal,
        AppUserRoleRef,
        AddUserRoleModal,
        SelectScopeModal,
    },

    data() {
        return {
            isLoading: false,
            profileChanged: false,
            avatarChanged: false,
        };
    },
    computed: {
        ...mapGetters(['appUserID']),
        ...mapState('scope/users', { userInfo: 'selected', lastTouch: 'selectedLastTouch' }),
        userRoles() { return this.userInfo ? this.userInfo.roles : []; },
        userName() { return this.userInfo ? this.userInfo.username : ''; },
        isSelf() { return this.userInfo != null && this.userInfo.id === this.appUserID; },
    },

    watch: {
        appID() { this.reload(); },
        userID() { this.reload(); },
    },

    created() { this.reload(); },

    methods: {
        ...mapActions('scope/users', {
            fetchUserInfo: ACTIONS.SELECT_USER,
            removeUser: ACTIONS.REMOVE_USER,
            moveUser: ACTIONS.MOVE_USER,
            updateUser: ACTIONS.UPDATE_USER,
            updateUserAvatar: ACTIONS.UPDATE_USER_AVATAR,
            updateUserPassword: ACTIONS.UPDATE_USER_PASSWORD,
            addUserRole: ACTIONS.ADD_USER_ROLE,
            removeUserRole: ACTIONS.REMOVE_USER_ROLE,
        }),

        reload() {
            this.isLoading = true;
            this.fetchUserInfo(this.userID).then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'app-users', params: { appID: this.appID } });
            });
        },

        submitUpdate() {
            // Так как обновление профиля и обновление аватара - разные операции, то
            // мы запускаем их парралелельно (если они были измененны) - и после окончания
            // обеих операций завершаем анимацию кнопки аплоада
            const updateProfile = new Promise((resolve, reject) => {
                if (!this.profileChanged) {
                    resolve();
                    return;
                }
                this.updateUser(this.$refs.userProfile.payload).then(resolve, reject);
            });

            const updateAvatar = new Promise((resolve, reject) => {
                if (!this.avatarChanged) {
                    resolve();
                    return;
                }

                this.updateUserAvatar({
                    userID: this.userInfo.id,
                    avatar: this.$refs.userAvatar.avatarData,
                }).then(() => {
                    this.$refs.userAvatar.reset();
                    resolve();
                }).catch(reject);
            });

            Promise.all([updateProfile, updateAvatar]).finally(() => {
                this.$refs.uploadBtn.stop();
                this.$refs.userProfile.reload();
                this.profileChanged = this.$refs.userProfile.changed;
            });
        },

        submitRemoveUser() {
            this.removeUser(this.userInfo.id).then(() => {
                this.$router.push({ name: 'app-users', params: { appID: this.appID } });
            });
        },

        submitMoveUser(e) {
            this.moveUser({ userID: this.userInfo.id, scopeID: e.scopeID }).then(() => {
                this.$router.push({ name: 'app-users', params: { appID: this.appID } });
            });
        },

        submitAddRole({ scope, role }) {
            this.addUserRole({
                userID: this.userInfo.id,
                role: {
                    scopeID: scope.id,
                    roleID: role.id,
                },
            }).then(() => {
                this.$refs.addRoleModal.hide();
            }).catch((err) => {
                this.$refs.addRoleModal.showError(err);
            });
        },

        submitRemoveRole(roleID) {
            this.removeUserRole({
                userID: this.userInfo.id,
                roleID,
            });
        },

        submitChangePassword(payload) {
            this.updateUserPassword({
                userID: this.userInfo.id,
                password: payload,
            }).then(() => {
                this.$refs.passwordModal.hide();
            }).catch((msg) => {
                this.$refs.passwordModal.showError(msg);
            });
        },
    },
};
