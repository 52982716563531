<template>
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title h5">Введите значение, которое будет указано
                    для всех разделов без аттрибута</div>
            </div>
            <div class="modal-body">
                <attribute-value ref="value" :attr="attr" />
            </div>
            <div class="modal-footer">
                <button class="btn mx-2" type="button" @click="hide()">Закрыть</button>
                <button class="btn btn-primary"
                    :disabled="$refs.value == null || !$refs.value.hasValue"
                    type="submit">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>

import AttributeValue from '@/components/common/AttributeValue';

export default {
    name: 'DefaultValueModal',

    components: { AttributeValue },

    data() {
        return {
            show: false,
            attr: null,
        };
    },

    methods: {
        open(attr) {
            this.attr = attr;
            this.show = true;
        },

        hide() {
            this.attr = null;
            this.show = false;
        },

        submit() {
            this.$emit('on-value', { attr: this.attr, value: this.$refs.value.getValue });
        },
    },
};

</script>
