<template>
    <div class="form-group" :class="{ 'has-error': errorMsg != null }">
        <div v-if="isLoading" class="loading loading-lg"></div>
        <div v-else-if="list.length == 0">
            <div class="text-center">Пользователей не найдено</div>
        </div>
        <div v-else>
            <label class="form-label">Выберете пользователя</label>
            <div :key="user.id" v-for="user in list">
                <button
                    type="button"
                    class="btn btn-link"
                    @click="selectUser(user)"
                    >{{user | userFullName}}</button>
            </div>

            <table-pagination :page="pages.page" :last="pages.count" @changed="changePage($event)"/>

            <p class="form-input-hint">{{errorMsg}}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.btn.btn-link {
    padding: 0;
    height: 1rem;
}
</style>

<script>
import _get from 'lodash/get';

import TablePagination from '@/components/common/TablePagination';

export default {
    name: 'AppUsersSelect',
    props: {
        listUsers: {
            type: Function,
        },
    },
    components: { TablePagination },

    data() {
        return {
            isLoading: false,
            errorMsg: null,

            list: [],
            pages: {
                page: 0,
                size: 10,
                count: 0,
            },
            lastTouch: 0,
        };
    },

    mounted() {
        this.isLoading = true;
        this.reload();
    },

    methods: {
        clear() {
            this.list = [];
            this.isLoading = false;
            this.pages.page = 0;
            this.pages.size = 10;
            this.pages.count = 0;
            this.lastTouch = 0;
            this.errorMsg = null;
        },

        reload(opts) {
            const size = _get(opts, 'size', this.pages.size);
            const offset = _get(opts, 'page', this.pages.current) * size;

            this.listUsers({ offset, size }).then((resp) => {
                const { list, pagination } = resp.data;
                this.list = list;
                this.pages.page = Math.floor(pagination.offset / pagination.pagesize);
                this.pages.size = pagination.pagesize;
                this.pages.count = Math.ceil(pagination.total / pagination.pagesize) - 1;
                this.lastTouch = new Date().getTime();
                this.errorMsg = null;
                this.isLoading = false;
            }).catch((err) => {
                this.clear();
                this.errorMsg = err.message;
            });
        },

        changePage(page) {
            this.reload({ page: page || 0 });
        },

        selectUser(user) {
            this.$emit('select-user', user);
        },
    },
};
</script>
