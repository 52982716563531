import { mapGetters, mapState, mapActions } from 'vuex';
import { UPDATE_LIST } from '@/store/scope/types/actions';

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';

import TypeItem from './TypeItem';

export default {
    name: 'AppTypesList',
    props: ['appID'],
    components: { AppLoading, AppNotFound, TypeItem },

    data() {
        return {
            isLoading: false,
        };
    },
    created() {
        this.reload();
    },

    watch: {
        appID() { this.reload(); },
    },

    computed: {
        ...mapGetters('profile', ['scopeRoles']),
        ...mapState('scope/types', { typesList: 'types' }),
        isEmpty() { return this.typesList == null || this.typesList.length === 0; },
    },

    methods: {
        ...mapActions('scope/types', {
            reloadTypes: UPDATE_LIST,
        }),

        reload() {
            this.isLoading = true;
            this.reloadTypes(this.appID).then(() => {
                this.isLoading = false;
            }).catch(() => {
                this.$router.push({ name: 'tasks' });
            });
        },
    },
};
