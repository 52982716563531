<template>
    <div v-if="process.subprocess" class="text-left">
        <div v-if="isAll">
            <label class="form-label">
                Будет запущено {{process.processes.scopes - process.processes.all}}
                подпроцессов <br>
                <i>{{process.subprocess.schema.name}}</i>
            </label>
        </div>
        <div v-else-if="isOne && count > 0">
            <label class="form-label">
                Будет запущен подпроцесс <br>
                <i>{{process.subprocess.schema.name}}</i> <br> в
            </label>
            <task-scope-ref :scope="selected[0]">
                <div class="tile-action">
                    <button class="btn btn-error tooltip tooltip-bottom"
                        data-tooltip="Отменить"
                        @click="switchScope(selected[0])">
                        <i class="icon icon-delete"></i>
                    </button>
                </div>
            </task-scope-ref>
        </div>
        <div v-else>
            <div v-if="isLoading" class="loading loading-lg"></div>
            <div v-else>
                <label class="form-label" v-if="isOne">
                    Укажите раздел в котором будет запущен
                    подпроцесс <br>
                    <i>{{process.subprocess.schema.name}}</i>
                </label>
                <label class="form-label" v-if="!isOne">
                    Укажите разделы в котором будет запущены
                    подпроцессы <br>
                    <i>{{process.subprocess.schema.name}}</i>
                </label>

                <p v-if="!isOne" class="form-input-hint">Выбрано {{ selected.length }} разделов</p>

                <div :key="scope.id" v-for="scope in list">
                    <div class="form-group" v-if="isOne">
                        <label @click="switchScope(scope)">
                            <task-scope-ref class="c-hand" :scope="scope" />
                        </label>
                    </div>
                    <div class="form-group" v-if="!isOne">
                        <label class="form-checkbox">
                            <input type="checkbox"
                                :checked="hasScope(scope)"
                                @click="switchScope(scope)">
                            <i class="form-icon"></i>
                            <task-scope-ref class="c-hand" :scope="scope" />
                        </label>
                    </div>
                </div>

                <table-pagination :page="pages.page" :last="pages.count"
                    @changed="changePage($event)"/>
            </div>
        </div>
        <label class="form-label">Подпроцессы стартуют с</label>
        <select class="form-select" v-model="formID"
            :disabled="process.subprocess.forms.length < 2">
            <option v-for="form in process.subprocess.forms"
                :key="form.id" :value="form.id">{{form.name}}</option>
        </select>

        <div class="columns my-2">
            <div class="column col-auto">
                <button class="btn btn-primary"
                    @click="close()">
                    Закрыть
                </button>
            </div>
            <div class="column text-right">
                <button class="btn btn-primary"
                    @click="patchProcess()">
                    Дозапустить
                </button>
            </div>
        </div>

        <error-msg :error="error" />
    </div>
</template>

<style lang="scss" scoped>
.form-input-hint {
    margin-bottom: 0;
}

.form-checkbox {
    margin: 0;
    .form-icon {
        top: 16px;
    }
}
</style>

<script>
import _get from 'lodash/get';

import Api from '@/api';

import TablePagination from '@/components/common/TablePagination';
import ErrorMsg from '@/components/common/ErrorMsg';

import TaskScopeRef from '@/components/tasks/TaskScopeRef';

export default {
    name: 'ProcessPatchPanel',
    components: { TablePagination, TaskScopeRef, ErrorMsg },

    props: { process: { type: Object } },

    data() {
        return {
            isLoading: false,
            error: null,

            list: [],
            pages: {
                page: 0,
                size: 10,
                count: 0,
            },
            lastTouch: 0,

            selected: [],
            formID: null,
        };
    },

    computed: {
        isOne() { return this.process.subprocess.type === 'one'; },
        isAll() { return this.process.subprocess.type === 'all'; },
    },

    created() {
        this.reload();
        this.formID = this.process.subprocess.forms ? this.process.subprocess.forms[0].id : -1;
    },

    methods: {
        clear() {
            this.list = [];
            this.isLoading = false;
            this.pages.page = 0;
            this.pages.size = 10;
            this.pages.count = 0;
            this.lastTouch = 0;
            this.error = null;
            this.selected = [];
        },

        close() {
            this.$emit('close');
        },

        patchProcess() {
            const payload = {
                subform: this.formID,
            };

            if (!this.isAll) {
                payload.scopes = this.selected.map(s => s.id);
            }

            this.$emit('patch', payload);
        },

        reload(opts) {
            const size = _get(opts, 'size', this.pages.size);
            const offset = _get(opts, 'page', this.pages.current) * size;

            if (this.isAll) {
                return;
            }

            Api.listProcessNotRunnedScopes(this.process.pid, { offset, size }).then((resp) => {
                const { list, pagination } = resp.data;
                this.list = list;
                this.pages.page = Math.floor(pagination.offset / pagination.pagesize);
                this.pages.size = pagination.pagesize;
                this.pages.count = Math.ceil(pagination.total / pagination.pagesize) - 1;
                this.lastTouch = new Date().getTime();
                this.error = null;
                this.isLoading = false;
            }).catch((err) => {
                this.clear();
                this.error = err;
            });
        },

        changePage(page) {
            this.reload({ page: page || 0 });
        },

        switchScope(scope) {
            for (let idx = 0; idx < this.selected.length; idx += 1) {
                if (this.selected[idx].id === scope.id) {
                    this.selected.splice(idx, 1);
                    return;
                }
            }
            this.selected.push(scope);
        },

        hasScope(scope) {
            for (let idx = 0; idx < this.selected.length; idx += 1) {
                if (this.selected[idx].id === scope.id) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
