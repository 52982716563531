<template>
    <div>
        <div :key="report.id" v-for="report in list" class="report-item">
            <div class="form-group">
                <router-link custom v-slot="{ navigate }" :to="{
                    name: 'process-admin-info',
                    params: { processID: report.pid }
                }" >
                    <div @click="navigate">
                        <task-item class="c-hover-hand" :task="report" />
                    </div>
                </router-link>
            </div>
        </div>

        <table-pagination :page="pages.page" :last="pages.count" @changed="changePage($event)"/>

        <error-msg :error="error" />
    </div>
</template>

<script>
import _get from 'lodash/get';

import Api from '@/api';

import TablePagination from '@/components/common/TablePagination';
import ErrorMsg from '@/components/common/ErrorMsg';

import TaskItem from '@/components/tasks/TaskItem';

export default {
    name: 'TaskReports',
    props: ['processID', 'stateID'],
    components: { TablePagination, TaskItem, ErrorMsg },

    data() {
        return {
            isLoading: false,
            error: null,

            list: [],
            pages: {
                page: 0,
                size: 10,
                count: 0,
            },
        };
    },

    created() { this.reload(); },

    methods: {
        clear() {
            this.list = [];
            this.isLoading = false;
            this.pages.page = 0;
            this.pages.size = 10;
            this.pages.count = 0;
            this.errorMsg = null;
        },

        reload(opts) {
            const size = _get(opts, 'size', this.pages.size);
            const offset = _get(opts, 'page', this.pages.current) * size;

            Api.listProcessSubProcesses(this.processID, this.stateID, {
                offset, size,
            }).then((resp) => {
                const { list, pagination } = resp.data;
                this.list = list;
                this.pages.page = Math.floor(pagination.offset / pagination.pagesize);
                this.pages.size = pagination.pagesize;
                this.pages.count = Math.ceil(pagination.total / pagination.pagesize) - 1;
                this.error = null;
                this.isLoading = false;
            }).catch((err) => {
                this.clear();
                this.error = err;
            });
        },

        changePage(page) {
            this.reload({ page: page || 0 });
        },
    },
};
</script>
