<template>
    <!-- Модальное окно выбора роли системы -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title text-center h5"
                    >Выбрать роль для формы {{form.name}}</div>
            </div>
            <div class="modal-body content form text-left"
                :class="{ 'has-error': errorMsg != null }">
                <app-scope-select ref="scopes"
                    :root="root"
                    @select-scope="updateScope($event)" />
                <app-type-select ref="types"
                    :listTypes="listTypes"
                    @select-type="updateType($event)" />
                <div class="form-group" v-if="roles.length > 0">
                    <label class="form-label">Укажите роль</label>
                    <div class="py-1">
                        <select class="form-select" v-model="role">
                            <option
                                v-for="role in roles"
                                :key="role.id"
                                :value="role"
                            >{{role.name}}</option>
                        </select>
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">Ролей не найдено</div>
                </div>
                <div v-if="errorMsg != null">
                    <p class="form-input-hint">{{errorMsg}}</p>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    class="btn btn-primary"
                    :disabled="role === null"
                    type="submit"
                >Выбрать</button>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
.modal {
    align-items: flex-start;
    .modal-container {
        overflow: visible;
        .modal-body {
            padding: 0 0.8rem;
            overflow: visible;
        }
    }
}
</style>

<script>

import AppScopeSelect from '@/components/common/AppScopeSelect';
import AppTypeSelect from '@/components/common/AppTypeSelect';

import Api from '@/api';

export default {
    name: 'SelectRoleModal',
    props: {
        root: {
            type: Object,
        },
    },
    components: { AppScopeSelect, AppTypeSelect },

    data() {
        return {
            show: false,
            form: {},
            scope: null,
            type: null,
            roles: [],
            role: null,
            errorMsg: null,
        };
    },

    computed: {
        listTypes() {
            return Api.typeMetaTypes;
        },
    },

    methods: {
        open(form) {
            this.form = form;
            this.scope = this.root;
            this.type = null;
            this.roles = [];
            this.role = null;
            this.errorMsg = null;

            this.show = true;

            this.$refs.scopes.reload();
            this.reloadTypes();
        },

        hide() {
            this.show = false;
        },

        updateScope(scope) {
            if (this.scope == null || this.scope.id !== scope.id) {
                this.scope = scope;
                this.reloadTypes();
            }
        },

        updateType(type) {
            if (this.type == null || this.type.id !== type.id) {
                this.type = type;
                this.reloadRoles();
            }
        },

        reloadTypes() {
            this.type = null;
            this.roles = [];
            this.role = null;
            Api.scopeTypes(this.scope.id).then((resp) => {
                this.$refs.types.reload({
                    name: this.scope.name,
                    types: resp.data,
                });
                this.reloadRoles();
                this.errorMsg = null;
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        reloadRoles() {
            this.roles = [];
            this.role = null;
            let promise;
            if (this.type == null || this.type.id == null) {
                promise = Api.scopeRoles(this.scope.id);
            } else {
                promise = Api.typeRoles(this.type.id);
            }

            promise.then((resp) => {
                if (resp.data.length > 0) {
                    this.roles = resp.data;
                    [this.role] = this.roles;
                }
                this.errorMsg = null;
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        submit() {
            this.$emit('select-role', {
                form: this.form,
                scope: this.scope,
                role: this.role,
            });
        },
    },
};
</script>
