<template>
    <form v-if="payload" class="form text-left container my-2">
        <div class="form-group">
            <label class="form-label">Название</label>
            <input class="form-input" type="text"
                v-model="payload.name" placeholder="Название">
        </div>
        <div class="form-group">
            <label class="form-label">Описание</label>
            <textarea class="form-input" type="text" rows="3"
                v-model="payload.description" />
        </div>

        <div class="form-group form-horizontal">
            <label class="form-label">Столбцы (не включая обязательный столбец Имени строки)</label>

            <div v-for="(column, idx) in payload.columns" :key="idx" class="form-group columns">
                <div class="column">
                    <input class="form-input" type="text"
                        v-model="column.name" placeholder="Имя столбца">
                </div>
                <div class="column col-auto text-right">
                    <select class="form-select form-inline"
                        v-model="column.type">
                        <option value="string">Строка</option>
                        <option value="text">Текст</option>
                        <option value="number">Число</option>
                        <option value="enum">Перечисление</option>
                        <option value="masked">Маска</option>
                        <option value="boolean">Булеан</option>
                        <option value="registry" disabled>Реестр</option>
                        <option value="media" disabled>Медиа</option>
                    </select>
                    <button class="btn" type="button" v-if="column.is_required"
                        @click="column.is_required = false">
                        <i class="icon icon-check"></i>
                        Обязательно
                    </button>
                    <button class="btn" type="button" v-if="!column.is_required"
                        @click="column.is_required = true">
                        Не обязательно
                    </button>
                    <button class="btn btn-success mx-1" type="button"
                            :disabled="column.type !== 'enum'
                                && column.type !== 'masked'
                                && column.type !== 'boolean'"
                            @click="openConfigModal(column)">
                        <i class="icon icon-edit"></i>
                    </button>
                    <button class="btn btn-error" type="button"
                        @click="deleteColumn(idx)">
                        <i class="icon icon-delete"></i>
                    </button>
                    <button class="btn mx-1" type="button"
                        :disabled="idx == payload.columns.length - 1"
                        @click="swapColumns(idx + 1)">
                        <i class="icon icon-arrow-down"></i>
                    </button>
                    <button class="btn" type="button"
                        :disabled="idx == 0" @click="swapColumns(idx)">
                        <i class="icon icon-arrow-up"></i>
                    </button>
                </div>
            </div>

            <button class="btn btn-success" type="button" @click="addColumn()">
                <i class="icon icon-plus"></i>
                Добавить
            </button>

            <enum-config-modal ref="enumConfigModal" />
            <mask-config-modal ref="maskConfigModal" />
            <boolean-config-modal ref="booleanConfigModal" />
        </div>

        <slot :payload="payload" :isValid="isValid" :isChanged="isChanged" />
    </form>
</template>

<style lang="scss">
.form-select.form-inline {
    width: 160px;
    margin-right: 10px;
}
</style>

<script>

import MaskConfigModal from '@/components/common/config/MaskConfigModal';
import EnumConfigModal from '@/components/common/config/EnumConfigModal';
import BooleanConfigModal from '@/components/common/config/BooleanConfigModal';

function cloneObj(obj) {
    return obj != null ? Object.assign({}, obj) : null;
}

function init() {
    return {
        name: null,
        description: null,
        columns: [],
    };
}

function clone(r) {
    return {
        name: r.name,
        description: r.description,
        columns: r.columns.map(cloneObj),
    };
}

function equals(one, two) {
    if (one.name !== two.name || one.description !== two.description) {
        return false;
    }
    if (one.columns.length !== two.columns.length) {
        return false;
    }

    for (let i = 0; i < one.columns.length; i += 1) {
        const a = one.columns[i];
        const b = two.columns[i];
        if (a.id !== b.id
            || a.name !== b.name
            || a.type !== b.type
            || a.is_required !== b.is_required
            || a.order !== b.order
            || a.options !== b.options) {
            return false;
        }
    }
    return true;
}

export default {
    name: 'RegistryForm',
    props: ['registry'],

    components: { EnumConfigModal, MaskConfigModal, BooleanConfigModal },

    data() {
        return {
            payload: null,
        };
    },

    created() { this.reload(); },
    watch: {
        registry() { this.reload(); },
    },

    computed: {
        isValid() {
            if (!this.payload) {
                return false;
            }
            if (!this.payload.name || this.payload.name.length === 0) {
                return false;
            }
            for (let idx = 0; idx < this.payload.columns.length; idx += 1) {
                const column = this.payload.columns[idx];
                if (!column.name || column.name.length === 0) {
                    return false;
                }
            }
            return true;
        },

        isChanged() {
            return this.registry != null && !equals(this.registry, this.payload);
        },
    },

    methods: {
        reload() {
            this.payload = this.registry ? clone(this.registry) : init();
        },

        addColumn() {
            const order = this.payload.columns.length + 1;
            this.payload.columns.push({
                name: '',
                type: 'string',
                is_required: false,
                options: null,
                order,
            });
        },

        deleteColumn(idx) {
            this.payload.columns.splice(idx, 1);
        },

        swapColumns(idx) {
            if (idx > 0) {
                const a = this.payload.columns[idx - 1];
                this.payload.columns.splice(idx - 1, 1);
                this.payload.columns.splice(idx, 0, a);

                // Пересчитываем order
                for (let i = 0; i < this.payload.columns.length; i += 1) {
                    this.payload.columns[i].order = i + 1;
                }
            }
        },

        openConfigModal(column) {
            const complete = (opts) => {
                // eslint-disable-next-line no-param-reassign
                column.options = opts;
            };
            if (column.type === 'enum') {
                this.$refs.enumConfigModal.open(column.options, complete);
            }
            if (column.type === 'masked') {
                this.$refs.maskConfigModal.open(column.options, false, complete);
            }
            if (column.type === 'boolean') {
                this.$refs.booleanConfigModal.open(column.options, complete);
            }
        },
    },
};
</script>
