<template>
    <div class="tile tile-centered">
        <div class="tile-icon">
            <div class="app-tile-icon">
                <i class="icon icon-flag centered"></i>
            </div>
        </div>
        <div class="tile-content">
            <p class="tile-title">{{role.name}}</p>
            <p class="tile-subtitle text-gray">{{role.scope.name}}</p>
        </div>
        <div class="tile-action">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppUserRoleRef',
    props: ['role'],
};
</script>
