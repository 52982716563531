<template>
    <div>
        <div v-if="!formOpened">
            <process-task :task="task">
                <div v-if="editable">
                    <button class="btn btn-primary mx-1"
                        type="button" @click="openForm()">
                        <i class="icon icon-edit"></i>
                    </button>
                </div>
            </process-task>
        </div>
        <div v-else>
            <div v-for="value in payload" :key="value.id">
                <label class="form-label">
                    <span>{{value.name}}</span>
                </label>
                <div v-if="value.type == 'string'">
                    <input class="form-input" type="text" v-model="value.value">
                </div>
                <div v-else-if="value.type == 'number'">
                    <input class="form-input" type="number" step="any" v-model="value.value">
                </div>
                <div v-else-if="value.type == 'text'">
                    <textarea class="form-input" type="text" rows="3" v-model="value.value" />
                </div>
                <div v-else-if="value.type == 'label'">
                    <i>{{value.label}}</i>
                </div>
                <div v-else-if="value.type == 'enum'">
                    <select class="form-select" v-model="value.value">
                        <option v-for="option in value.list" :key="option.id"
                            :value="option.id">{{option.name}}</option>
                    </select>
                </div>
                <div v-else-if="value.type == 'media'" class="columns">
                    <div class="column col-4 col-sm-12">
                        <file-upload
                            accept="*"
                            @upload-file="uploadFile(value, $event)"
                        >Загрузить новый файл</file-upload>
                    </div>
                    <div class="column col-8 col-sm-12">
                        <div :key="file.id" v-for="file in value.files">
                            <file-info :file="file">
                                <div class="tile-action">
                                    <a class="btn btn-primary tooltip tooltip-bottom"
                                        data-tooltip="Скачать"
                                        target="_blank" rel="noopener noreferrer"
                                        :href="`/api/files/t/${task.id}/${file.id}/content`">
                                        <i class="icon icon-download"></i>
                                    </a>
                                </div>
                                <div class="tile-action">
                                    <button class="btn btn-error tooltip tooltip-bottom"
                                        data-tooltip="Удалить" @click="removeFile(value, file.id)">
                                        <i class="icon icon-delete"></i>
                                    </button>
                                </div>
                            </file-info>
                        </div>
                    </div>
                </div>
                <div v-else class="has-error">
                    <div class="form-input-hint">
                        Данный тип аттрибута не поддерживается
                    </div>
                </div>
            </div>

            <div class="columns my-2">
                <div class="column col-auto">
                    <button class="btn btn-primary"
                        @click="closeForm()">
                        Закрыть
                    </button>
                </div>
                <div class="column text-right">
                    <button class="btn btn-primary"
                        @click="patchTask()">
                        Обновить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Api from '@/api';

import FileUpload from '@/components/common/FileUpload';
import FileInfo from '@/components/common/FileInfo';

import ProcessTask from './ProcessTask';

function fromValue(value) {
    const payload = {
        id: value.id,
        name: value.name,
        type: value.type,
        value: value.value,
    };
    if (value.type === 'enum') {
        payload.list = JSON.parse(value.options).options;
    }
    if (value.type === 'media') {
        payload.files = [...value.files];
    }
    if (value.type === 'label') {
        payload.label = value.options;
    }

    return payload;
}

function toValue(payload) {
    const value = {
        id: payload.id,
    };
    if (payload.type === 'media') {
        value.files = payload.files.map(f => f.id);
    } else {
        value.value = payload.value;
    }
    return value;
}

export default {
    name: 'ProcessTaskForm',
    props: ['task', 'editable'],
    components: { ProcessTask, FileInfo, FileUpload },

    data() {
        return {
            formOpened: false,
            payload: [],
        };
    },

    methods: {
        openForm() {
            this.payload = this.task.values.map(fromValue);
            this.formOpened = true;
        },

        closeForm() {
            this.payload = [];
            this.formOpened = false;
        },

        uploadFile(value, file) {
            const data = new FormData();
            data.append('file', file);
            Api.uploadFile(data).then((resp) => {
                value.files.push(resp.data);
            });
        },

        removeFile(value, fileID) {
            for (let idx = value.files.length - 1; idx >= 0; idx -= 1) {
                if (value.files[idx].id === fileID) {
                    value.files.splice(idx, 1);
                }
            }
        },

        patchTask() {
            const values = this.payload.map(toValue);
            this.$emit('patch', { values });
            this.payload = [];
            this.formOpened = false;
        },
    },
};
</script>
