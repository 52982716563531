<template>
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title h5">Перечисление</div>
            </div>
            <div class="modal-body">
                <div class="content text-left">
                    <div v-for="(item, idx) in list" :key="item.code" class="form-group columns">
                        <div class="column">
                            <input class="form-input"
                                type="text" placeholder="Название варианта"
                                v-model="item.text">
                        </div>
                        <div class="column col-auto">
                            <button class="btn btn-error" type="button"
                                :disabled="item.used"
                                @click="removeLine(idx)">
                                <i class="icon icon-delete"></i>
                            </button>
                        </div>
                    </div>

                    <div class="form-group py-2 text-right">
                        <button class="btn btn-success" type="button"
                            @click="addLine()">
                            <i class="icon icon-plus"></i>
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" :disabled="!canSubmit"
                    type="submit">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    name: 'EnumConfigModal',
    data() {
        return {
            show: false,
            attr: null,
            nextValue: null,
            list: [],
        };
    },

    computed: {
        canSubmit() {
            return this.list.filter(i => i.text != null && i.text.length > 0).length > 0;
        },
    },

    methods: {
        open(attr, values) {
            this.attr = attr;
            this.usedValues = values;
            this.nextValue = null;
            const list = attr.options ? JSON.parse(attr.options) : [];
            for (let idx = 0; idx < list.length; idx += 1) {
                const { code } = list[idx];
                list[idx].used = values.filter(v => String(v) === String(code)).length > 0;
            }
            this.list = list;
            this.show = true;
        },

        hide() {
            this.attr = null;
            this.list = [];
            this.nextValue = null;
            this.show = false;
        },

        submit() {
            const list = this.list
                .filter(i => i.text != null && i.text.length > 0)
                .map(i => ({
                    code: i.code,
                    text: i.text,
                }));
            this.attr.options = JSON.stringify(list);
            this.list = [];
            this.nextValue = null;
            this.attr = null;
            this.show = false;
        },

        addLine() {
            const currentCodes = this.list.map(i => i.code);
            const code = (currentCodes.length > 0 ? Math.max(...currentCodes) : 0) + 1;
            this.list.push({
                code,
                text: '',
                used: false,
            });
        },

        removeLine(idx) {
            this.list.splice(idx, 1);
        },
    },
};

</script>
