<template>
    <div class="form-group" :class="{ 'has-error': errorMsg != null }">
        <div v-if="types.length == 0">
            <div class="text-center">Типов не найдено</div>
        </div>
        <div v-else>
            <label class="form-label">Укажите тип</label>
            <div :key="idx" v-for="(type, idx) in types" class="py-1">
                <select class="form-select" v-model="type.selected" :change="updateType(idx)">
                    <option :value="null">-- {{type.name}} --</option>
                    <option
                        v-for="child in type.childs"
                        :key="child.id"
                        :value="child"
                    >{{child.name}}</option>
                </select>
            </div>
            <p class="form-input-hint">{{errorMsg}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppTypeSelect',

    props: {
        listTypes: {
            type: Function,
        },
    },

    data() {
        return {
            errorMsg: null,
            types: [],
        };
    },

    methods: {
        reload({ name, types }) {
            this.types = [];
            if (types.length > 0) {
                this.types.push({
                    id: null,
                    name,
                    childs: types,
                    selected: null,
                });
            }
        },

        pushType(type) {
            const { id, name } = type;
            this.listTypes(id).then((resp) => {
                if (resp.data.length > 0) {
                    const ss = {
                        id,
                        name,
                        selected: null,
                        childs: resp.data,
                    };
                    this.types.push(ss);
                }
                this.$emit('select-type', { id, name });
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        updateType(level) {
            if (level >= this.types.length) {
                return;
            }

            const ss = this.types[level].selected;

            if (level < this.types.length - 1) {
                if (ss != null && this.types[level + 1].id === ss.id) {
                    return;
                }
                this.types = this.types.slice(0, level + 1);
            }

            if (ss !== null) {
                this.pushType(ss);
            } else {
                const { id, name } = this.types[level];
                this.$emit('select-type', { id, name });
            }
        },
    },
};
</script>
