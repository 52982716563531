<template>
    <div class="tile tile-centered">
        <div class="tile-icon">
            <div class="app-tile-icon">
                <i class="icon icon-flag centered"></i>
            </div>
        </div>
        <div class="tile-content">
            <p class="tile-title">{{role.name}}</p>
            <p class="tile-subtitle text-gray">{{description}}</p>
        </div>
        <div class="tile-action">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RoleItem',
    props: ['role'],
    computed: {
        description() {
            const d = [
                this.role.scopes ? 'Разделы' : null,
                this.role.types ? 'Типы и роли' : null,
                this.role.users ? 'Пользователи' : null,
                this.role.processes ? 'Процессы' : null,
                this.role.schemas ? 'Схемы' : null,
                this.role.registries ? 'Реестры' : null,
                this.role.pviewer ? 'Наблюдатель процессов' : null,
            ].filter(i => i != null).join(', ');
            return d.length > 0 ? d : 'Нет никаких прав';
        },
    },
};
</script>
