<template>
    <div>
        <div class="form-group columns text-right">
            <div class="column">
                <search-box v-model="filterSearch" @change="updateSearch()" />
            </div>
            <div class="column col-auto">
                <select class="form-select" v-model="filterOrder"
                    @change="updateOrder()">
                    <option value="name">Имя ⇑</option>
                    <option value="name_desc">Имя ⇓</option>
                    <option value="date">Время создания ⇓</option>
                    <option value="date_desc">Время создания ⇑</option>
                </select>
            </div>
        </div>
        <div class="form-group columns text-right">
            <div class="column col-auto" v-if="hasFilterType">
                <select class="form-select form-inline" v-model="filterType"
                    @change="updateType()">
                    <option :value="null">Любой тип</option>
                    <option v-for="tt in available.type"
                        :key="tt.id" :value="tt.id">{{tt.name}}</option>
                </select>
            </div>
            <div class="column col-auto" v-if="hasFilterAttr">
                <select class="form-select form-inline" v-model="filterAttr"
                    @change="updateAttr()">
                    <option :value="null">-- Не выбрано --</option>
                    <option v-for="tt in available.attr"
                        :key="tt.id" :value="tt.id">{{tt.name}}</option>
                </select>
            </div>
            <div class="column" v-if="hasFilterValue">
                <div v-if="available.value.type == 'string'
                    || available.value.type == 'text'
                    || available.value.type == 'masked'">
                    <search-box v-model="filterValue" @change="updateValue()" />
                </div>
                <div v-else-if="available.value.type == 'enum'">
                    <select class="form-select" v-model="filterValue" @change="updateValue()">
                        <option :value="null">-- Не выбрано --</option>
                        <option v-for="item in enumList" :key="item.code"
                            :value="item.code">{{item.text}}</option>
                    </select>
                </div>
                <div v-else class="has-error">
                    <div class="form-input-hint">
                        Данный тип аттрибута не поддерживается
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.form-inline {
    margin-right: 2px;
}
.column.col-auto {
    select {
        width: auto;
        max-width: 250px;
    }
}
</style>

<script>
import SearchBox from '@/components/common/SearchBox';

export default {
    name: 'ScopesFilters',
    props: ['available', 'filters'],
    components: {
        SearchBox,
    },

    data() {
        return {
            filterType: null,
            filterAttr: null,
            filterValue: null,
            filterSearch: null,
            filterOrder: null,
        };
    },
    created() {
        this.reloadFilters(this.filters || {});
    },

    watch: {
        filters: 'reloadFilters',
    },

    computed: {
        hasFilterType() {
            return this.available && this.available.type && this.available.type.length > 0;
        },
        hasFilterAttr() {
            return this.available && this.available.attr && this.available.attr.length > 0;
        },
        hasFilterValue() {
            return this.available && this.available.value && this.available.value.type;
        },

        enumList() {
            if (!this.available || !this.available.value || this.available.value.type !== 'enum') {
                return [];
            }
            return JSON.parse(this.available.value.options);
        },
    },

    methods: {
        reloadFilters(filters) {
            this.filterType = filters.type || null;
            this.filterAttr = filters.attr || null;
            this.filterValue = filters.value || null;
            this.filterSearch = filters.search || null;
            this.filterOrder = filters.orderBy || null;
        },

        updateType() { this.$emit('set-type', this.filterType); },
        updateAttr() { this.$emit('set-attr', this.filterAttr); },
        updateValue() { this.$emit('set-value', this.filterValue); },

        updateSearch() { this.$emit('set-search', this.filterSearch); },
        updateOrder() { this.$emit('set-order', this.filterOrder); },
    },
};
</script>
