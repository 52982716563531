<template>
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="submit()">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title h5">Перечисление</div>
            </div>
            <div class="modal-body">
                <div class="content text-left">
                    <div v-for="(item, idx) in list" :key="item.code" class="form-group columns">
                        <div class="column">
                            <input class="form-input"
                                type="text" placeholder="Название варианта"
                                v-model="item.name">
                        </div>
                        <div class="column col-auto">
                            <button class="btn btn-error" type="button"
                                :disabled="item.used"
                                @click="removeLine(idx)">
                                <i class="icon icon-delete"></i>
                            </button>
                        </div>
                    </div>

                    <div class="form-group py-2 text-right">
                        <button class="btn btn-success" type="button"
                            @click="addLine()">
                            <i class="icon icon-plus"></i>
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" :disabled="!canSubmit"
                    type="submit">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    name: 'EnumConfigModal',
    data() {
        return {
            show: false,
            complete: null,
            list: [],
        };
    },

    computed: {
        canSubmit() {
            return this.list.filter(i => i.name != null && i.name.length > 0).length > 0;
        },
    },

    methods: {
        open(options, complete) {
            const list = options ? JSON.parse(options) : [];
            this.list = list.map(i => ({
                id: i.id,
                name: i.name,
                used: false,
            }));
            this.show = true;
            this.complete = complete;
        },

        hide() {
            this.list = [];
            this.complete = null;
            this.show = false;
        },

        submit() {
            const list = this.list
                .filter(i => i.name != null && i.name.length > 0)
                .map(i => ({
                    id: i.id,
                    name: i.name,
                }));
            this.list = [];
            this.show = false;
            this.complete(JSON.stringify(list));
            this.complete = null;
        },

        addLine() {
            const currentIDs = this.list.map(i => i.id);
            const nextID = (currentIDs.length > 0 ? Math.max(...currentIDs) : 0) + 1;
            this.list.push({
                id: nextID,
                name: '',
                used: false,
            });
        },

        removeLine(idx) {
            this.list.splice(idx, 1);
        },
    },
};

</script>
