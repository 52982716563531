<template>
    <div>
        <div v-if="isLoading" class="loading loading-lg"></div>
        <div v-else class="text-left">
            <label class="form-label text-center">
                Переместить процесс {{process.name}} в раздел
            </label>

            <scopes-filters :available="scopesAvailable" :filters="scopesFilters"
                @set-type="setFilterType($event)"
                @set-attr="setFilterAttr($event)"
                @set-value="setFilterValue($event)"
                @set-search="setFilterSearch($event)"
                @set-order="setFilterOrder($event)"
                />

            <div :key="scope.id" v-for="scope in scopesList">
                <div class="form-group">
                    <label @click="selectScope(scope)">
                        <task-scope-ref class="c-hand" :scope="scope" />
                    </label>
                </div>
            </div>
            <table-pagination
                :page="scopesPages.page" :last="scopesPages.count"
                @changed="changePage($event)"/>
            <errorMsg :error="error" />
        </div>

        <div class="columns">
            <div class="column col-auto">
                <button class="btn btn-primary"
                    @click="close()">
                    Закрыть
                </button>
            </div>
        </div>

        <remove-confirm-modal ref="confirmModal" action="Переместить"
            @confirm-remove="moveToScope()" >
            <div class="h5">Вы действительно хотите перести
                процесс {{process.name}} в раздел
                {{moveScope != null ? moveScope.name : ''}}?</div>
        </remove-confirm-modal>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

import * as A from '@/store/mprocess/movable/actions';

import TablePagination from '@/components/common/TablePagination';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal';
import ErrorMsg from '@/components/common/ErrorMsg';
import ScopesFilters from '@/components/scope/scopes/ScopesFilters';

import TaskScopeRef from '@/components/tasks/TaskScopeRef';

export default {
    name: 'ProcessMovePanel',
    props: ['process'],
    components: {
        TablePagination, TaskScopeRef, ErrorMsg, RemoveConfirmModal, ScopesFilters,
    },

    data() {
        return {
            isLoading: false,
            moveScope: null,
            error: null,
        };
    },

    created() {
        this.reload();
    },

    watch: {
        scopesFilters: 'reloadFilters',
    },

    computed: {
        ...mapState('mprocess/movable', {
            scopesList: 'list',
            scopesPages: 'pages',
            scopesFilters: 'filters',
            scopesAvailable: 'available',
        }),

        hasFilterType() {
            return this.scopesAvailable.type && this.scopesAvailable.type.length > 0;
        },
    },

    methods: {
        ...mapActions('mprocess/movable', {
            fetchScopes: A.UPDATE_LIST,
            changePage: A.CHANGE_PAGE,
            setFilterType: A.FILTER_TYPE,
            setFilterAttr: A.FILTER_ATTR,
            setFilterValue: A.FILTER_VALUE,
            setFilterSearch: A.FILTER_SEARCH,
            setFilterOrder: A.FILTER_ORDER,
        }),

        reloadFilters(filters) {
            this.filterType = filters.type || null;
            this.filterSearch = filters.search || null;
        },

        reload() {
            this.isLoading = true;
            this.fetchScopes().finally(() => {
                this.reloadFilters(this.scopesFilters);
                this.isLoading = false;
            });
        },

        selectScope(scope) {
            this.moveScope = scope;
            this.$refs.confirmModal.open();
        },

        moveToScope() {
            this.$refs.confirmModal.hide();
            this.$emit('move', { scopeID: this.moveScope.id });
        },

        close() {
            this.$emit('close');
        },
    },
};

</script>
