var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-header"},[_c('div',{staticClass:"h4"},[_vm._v(_vm._s(_vm.process.name))])]),_c('div',{staticClass:"panel-nav"},[_vm._t("nav")],2),_c('div',{staticClass:"panel-body text-left"},[(_vm.process.base)?_c('div',[(_vm.process.base.base)?_c('div',[_c('process-history',{attrs:{"process":_vm.process.base.base},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var link = ref.link;
return [_c('a',{staticClass:"btn btn-link",attrs:{"target":"_blank","rel":"noopener noreferrer","href":_vm.baseLinkUrl(link)}},[_vm._v(_vm._s(link.name))])]}},{key:"task",fn:function(ref){
var task = ref.task;
return [_c('process-task',{attrs:{"task":task}})]}}],null,false,1130784881)})],1):_vm._e(),_c('process-history',{attrs:{"process":_vm.process.base},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var link = ref.link;
return [_c('a',{staticClass:"btn btn-link",attrs:{"target":"_blank","rel":"noopener noreferrer","href":_vm.baseLinkUrl(link)}},[_vm._v(_vm._s(link.name))])]}},{key:"task",fn:function(ref){
var task = ref.task;
return [_c('process-task',{attrs:{"task":task}})]}}],null,false,1130784881)})],1):_vm._e(),_c('process-history',{attrs:{"process":_vm.process},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var link = ref.link;
return [_c('a',{staticClass:"btn btn-link",attrs:{"target":"_blank","rel":"noopener noreferrer","href":_vm.linkUrl(link)}},[_vm._v(_vm._s(link.name))])]}},{key:"taskProcesses",fn:function(ref){
var state = ref.state;
return [_c('process-sub-list',{attrs:{"processID":_vm.process.pid,"stateID":state.id}})]}},{key:"subProcesses",fn:function(){return [_c('process-sub-list',{attrs:{"processID":_vm.process.pid,"stateID":_vm.process.id}})]},proxy:true},{key:"task",fn:function(ref){
var task = ref.task;
return [_vm._t("task",null,{"task":task})]}}],null,true)})],1),_c('div',{staticClass:"panel-footer text-left"},[(!_vm.process.is_finish)?_c('div',[_c('div',{staticClass:"text-left h5"},[_vm._v("Назначены")]),(_vm.process.has_executors)?_c('div',{staticClass:"text-left"},_vm._l((_vm.processInfo.executors),function(user){return _c('div',{key:user.id,staticClass:"toast my-1"},[_vm._v(" Пользователь "+_vm._s(_vm._f("userFullName")(user))+" ")])}),0):_c('div',[_c('div',{staticClass:"text-left"},_vm._l((_vm.process.exec_roles),function(r){return _c('div',{key:r.id,staticClass:"toast my-1"},[_vm._v(" Все пользователи с ролью "+_vm._s(r.role.name)+" в разделе "+_vm._s(r.scope.name)+" ")])}),0),_c('div',{staticClass:"text-left"},_vm._l((_vm.process.exec_accounts),function(user){return _c('div',{key:user.id,staticClass:"toast my-1"},[_vm._v(" Пользователь "+_vm._s(_vm._f("userFullName")(user))+" ")])}),0)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }