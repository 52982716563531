<template>
    <div class="form-group" :class="{ 'has-error': errorMsg != null }">
        <div class="form-autocomplete">
            <div class="form-autocomplete-input form-input">
                <button
                    class="btn form-input text-left"
                    type="button"
                    @click="switchMenu()"
                    >{{selectedName}}</button>
            </div>

            <ul v-if="showMenu" class="menu text-left">
                <li class="menu-item">
                    <a class="btn btn-link" @click="select(null)">
                        {{root.name}}
                    </a>
                </li>
                <li class="divider" />
                <li>
                    <search-box v-model="searchText" :isRequest="requestCount > 0"
                        @change="reload()" />
                </li>
                <li class="menu-item" v-for="item of list" :key="item.id">
                    <a class="btn btn-link" @click="select(item)">
                        {{item.name}}
                    </a>
                </li>
                <li class="menu-item">
                    <table-pagination :page="pages.page"
                        :last="pages.count" @changed="changePage($event)"/>
                </li>
            </ul>
        </div>
        <p v-if="errorMsg != null" class="form-input-hint">{{errorMsg}}</p>
    </div>
</template>

<style lang="scss" scoped>
button.form-input {
    overflow: hidden;
}

.menu-item {
    padding: 0;
    a.btn {
        text-align: left;
        padding: 0 15px;
        height: 1.2rem;
        overflow: hidden;
    }
}
</style>>

<script>

import _get from 'lodash/get';
import TablePagination from '@/components/common/TablePagination';
import SearchBox from '@/components/common/SearchBox';

import Api from '@/api';

export default {
    name: 'AppSubScopesSelect',

    components: { TablePagination, SearchBox },

    props: {
        root: Object,
    },

    data() {
        return {
            errorMsg: null,
            showMenu: false,
            selected: null,
            requestCount: 0,

            searchText: null,
            list: [],
            pages: {
                page: 0,
                size: 10,
                count: 0,
            },
        };
    },

    computed: {
        selectedName() {
            return this.selected != null ? this.selected.name : 'Указать подраздел';
        },
    },

    watch: {
        root() {
            this.clear();
            this.reload();
        },
    },

    mounted() {
        this.reload();
    },

    methods: {
        clear() {
            this.selected = null;

            this.list = [];
            this.searchText = null;
            this.requestCount = 0;

            this.pages.page = 0;
            this.pages.size = 10;
            this.pages.count = 0;
        },

        reload(opts) {
            const size = _get(opts, 'size', this.pages.size);
            const offset = _get(opts, 'page', this.pages.current) * size;
            const search = this.searchText || null;

            this.requestCount += 1;

            Api.scopeSubscopes(this.root.id, { offset, size, filters: { search } }).then((resp) => {
                const { list, pagination, filters } = resp.data;
                this.list = list;
                this.searchText = (filters && filters.search) || null;
                this.pages.page = Math.floor(pagination.offset / pagination.pagesize);
                this.pages.size = pagination.pagesize;
                this.pages.count = Math.ceil(pagination.total / pagination.pagesize) - 1;
                this.errorMsg = null;
                if (this.requestCount > 0) {
                    this.requestCount -= 1;
                }
            }).catch((err) => {
                this.clear();
                this.errorMsg = err.message;
                if (this.requestCount > 0) {
                    this.requestCount -= 1;
                }
            });
        },

        changePage(page) {
            this.reload({ page: page || 0 });
        },

        switchMenu() {
            this.showMenu = !this.showMenu;
        },

        select(item) {
            this.selected = item;
            this.showMenu = false;
            this.$emit('select', this.selected);
        },
    },
};
</script>
