import _debounce from 'lodash/debounce';

import { mapActions } from 'vuex';
import { IMaskComponent } from 'vue-imask';

import { CREATE_USER } from '@/store/scope/users/actions';

import Api from '@/api';

const userInit = {
    id: null,
    username: null,
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    password: null,
    mobilePhone: {
        value: null,
        mask: '+{7}(000)000-00-00',
        is_completed: false,
    },
    localPhone: {
        value: null,
        mask: '00-00',
        is_completed: false,
    },
    phone: {
        value: null,
        mask: '+{7}(000)000-00-00',
        is_completed: false,
    },
    position: null,
    description: null,
};
const WATCH_USERNAME = 'user.username';

export default {
    name: 'CreateUser',
    props: ['appID'],
    data() {
        return {
            errorMsg: null,
            checking: null,
            checkMsg: null,
            user: { ...userInit },
        };
    },
    computed: {
        canSubmit() {
            return this.checking === 'good';
        },
    },

    components: {
        ImaskInput: IMaskComponent,
    },

    watch: {
        [WATCH_USERNAME](username) {
            this.checkMsg = null;
            this.checking = 'load';
            this.debouncedCheckUsername(username);
        },
    },

    created() {
        this.debouncedCheckUsername = _debounce(this.checkUsername, 200);
    },

    methods: {
        ...mapActions('scope/users', {
            createUser: CREATE_USER,
        }),

        checkUsername(username) {
            if (!username) {
                this.checkMsg = 'Логин пользователя не может быть пустым';
                this.checking = 'wrong';
                return;
            }
            Api.validateUsername(username).then(() => {
                this.checkMsg = null;
                this.checking = 'good';
            }).catch((e) => {
                if (e.response && e.response.status === 409) {
                    this.checkMsg = 'Данный логин уже зарегестрирован в системе';
                } else if (e.response && e.response.status === 400) {
                    if (username.length < 3) {
                        this.checkMsg = 'Логин содержит слишком мало символов';
                    } else {
                        this.checkMsg = 'Логин содержит недопустимые символы';
                    }
                } else {
                    this.checkMsg = e.message;
                }
                this.checking = 'wrong';
            });
        },

        submitCreateUser() {
            if (!this.user.username || this.checking !== 'good') {
                this.errorMsg = 'Необходимо ввести валидный логин пользователя';
                return;
            }
            if (!this.user.email || this.user.email.length === 0) {
                this.errorMsg = 'Необходимо ввести электронный адрес пользователя';
                return;
            }

            const masked = vv => (vv.is_completed ? vv.value : null);
            const payload = {
                id: null,
                username: this.user.username,
                firstName: this.user.firstName,
                middleName: this.user.middleName,
                lastName: this.user.lastName,
                email: this.user.email,
                password: this.user.password,
                mobilePhone: masked(this.user.mobilePhone),
                localPhone: masked(this.user.localPhone),
                phone: masked(this.user.phone),
                position: this.user.position,
                description: this.user.description,
            };

            this.createUser({ payload, appID: this.appID }).then((user) => {
                this.$router.push({ name: 'app-user-info', params: { appID: this.appID, userID: user.id } });
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },
    },
};
