import { mapState, mapActions, mapGetters } from 'vuex';

import Api from '@/api';

import * as A from '@/store/mprocess/actions';

import ErrorMsg from '@/components/common/ErrorMsg';
import AppLoading from '@/components/common/AppLoading';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal';

import ProcessForm from './ProcessForm';
import ProcessMovePanel from './ProcessMovePanel';
import ProcessPatchPanel from './ProcessPatchPanel';
import ProcessTaskForm from './ProcessTaskForm';

export default {
    name: 'ProcessAdminInfo',
    props: ['processID'],
    components: {
        ErrorMsg,
        AppLoading,
        ProcessForm,
        ProcessMovePanel,
        ProcessPatchPanel,
        ProcessTaskForm,
        RemoveConfirmModal,
    },

    data() {
        return {
            isLoading: false,
            mode: null,
            error: null,
        };
    },

    computed: {
        ...mapState('mprocess', {
            processInfo: 'process',
            lastTouch: 'lastTouch',
        }),
        ...mapGetters('profile', ['isDeveloper', 'scopeRoles']),

        reportXlsxURL() { return Api.processReportURL(this.processInfo.pid); },
        report2XlsxURL() { return Api.process2ReportURL(this.processInfo.pid); },
        report3XlsxURL() { return Api.process3ReportURL(this.processInfo.pid); },

        canComplete() { return this.processInfo != null && !this.processInfo.is_finish; },
        canMove() { return this.processInfo != null; },

        canPatch() {
            if (!this.processInfo || !this.processInfo.processes || !this.processInfo.subprocess) {
                return false;
            }
            if (this.processInfo.subprocess.type === 'one') {
                return this.processInfo.processes.all === 0;
            }
            return this.processInfo.processes.scopes > this.processInfo.processes.all;
        },

        isSuperAdmin() {
            return this.scopeRoles.isTypesManager
                && this.scopeRoles.isUsersManager
                && this.scopeRoles.isScopesManager
                && this.scopeRoles.isSchemasManager
                && this.scopeRoles.isProcessesManager;
        },
    },

    watch: {
        processID() { this.reload(); },
    },

    created() { this.reload(); },

    methods: {
        ...mapActions('mprocess', {
            fetchInfo: A.SELECT_PROCESS,
            updateProcess: A.UPDATE_PROCESS,
            patchProcess: A.PATCH_PROCESS,
            revertProcess: A.REVERT_PROCESS,
            removeProcess: A.REMOVE_PROCESS,
            moveProcess: A.MOVE_PROCESS,
            notifyProcess: A.NOTIFY_PROCESS,
            patchProcessTask: A.PATCH_TASK,
        }),

        reload() {
            this.isLoading = true;
            this.fetchInfo(this.processID).then(() => {
                this.isLoading = false;
                this.error = null;
            }).catch(() => {
                this.$router.push({ name: 'default' });
            });
        },

        submitPatch(payload) {
            this.patchProcess({ id: this.processInfo.pid, payload }).then(() => {
                this.mode = null;
                this.error = null;
            }).catch((err) => {
                this.error = err;
            });
        },

        submitNotify() {
            this.notifyProcess({ processID: this.processInfo.pid }).then(() => {
                this.mode = null;
                this.error = null;
            }).catch((err) => {
                this.error = err;
            });
        },

        submitPatchTask(taskID, payload) {
            this.patchProcessTask({ processID: this.processInfo.pid, taskID, payload }).then(() => {
                this.mode = null;
                this.error = null;
            }).catch((err) => {
                this.error = err;
            });
        },

        submitMove({ scopeID }) {
            const payload = {
                processID: this.processInfo.pid,
                scopeID,
            };

            this.moveProcess(payload).then(() => {
                this.mode = null;
                this.error = null;
            }).catch((err) => {
                this.error = err;
            });
        },

        submitRevert() {
            if (this.$refs.revertModal) {
                this.$refs.revertModal.hide();
            }

            this.revertProcess(this.processInfo.pid).then(() => {
                this.mode = null;
                this.error = null;
            }).catch((err) => {
                this.error = err;
            });
        },

        submitRemove() {
            const appID = this.processInfo.scope.id;

            if (this.$refs.removeModal) {
                this.$refs.removeModal.hide();
            }

            this.removeProcess(this.processInfo.pid).then(() => {
                this.$router.replace({ name: 'app-processes', params: { appID } });
            }).catch((err) => {
                this.error = err;
            });
        },
    },
};
