import { mapState, mapActions } from 'vuex';
import {
    UPDATE_LIST, UPLOAD_SCHEMA, CHANGE_PAGE, CHANGE_SIZE,
} from '@/store/scope/schemas/actions';

import AppLoading from '@/components/common/AppLoading';
import AppNotFound from '@/components/common/AppNotFound';
import ErrorMsg from '@/components/common/ErrorMsg';
import FileUpload from '@/components/common/FileUpload';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';

import ProcessSchemaItem from './ProcessSchemaItem';

export default {
    name: 'ProcessSchemasList',
    props: ['appID'],

    components: {
        AppLoading,
        AppNotFound,
        ErrorMsg,
        ProcessSchemaItem,
        FileUpload,
        TablePageSize,
        TablePagination,
    },

    data() {
        return {
            isLoading: false,
            error: null,
        };
    },
    created() {
        this.reload();
    },

    watch: {
        appID() { this.reload(); },
    },

    computed: {
        ...mapState('scope/schemas', {
            schemasList: 'list',
            schemasPage: 'pages',
        }),
        isEmpty() { return this.schemasList == null || this.schemasList.length === 0; },
    },

    methods: {
        ...mapActions('scope/schemas', {
            reloadSchemas: UPDATE_LIST,
            uploadSchema: UPLOAD_SCHEMA,
            changePage: CHANGE_PAGE,
            changeSize: CHANGE_SIZE,
        }),

        reload() {
            this.isLoading = true;
            this.reloadSchemas({ appID: this.appID }).finally(() => {
                this.isLoading = false;
            });
        },

        submitUploadSchema(payload) {
            this.uploadSchema(payload).then(() => {
                this.error = null;
            }).catch((err) => {
                this.error = err;
            });
        },
    },
};
