<template>
    <div v-if="error != null" class="has-error text-left">
        <div v-if="error.response">
            <span class="form-input-hint">{{error.response.statusText}}</span>
            <pre class="form-input-hint">{{error.response.data}}</pre>
        </div>
        <div v-else-if="error.stack">
            <pre class="form-input-hint">{{error.stack}}</pre>
        </div>
        <div v-else>
            <pre class="form-input-hint">{{error}}</pre>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorMsg',
    props: ['error'],
};
</script>
