import { mapActions, mapState } from 'vuex';

import * as ACTIONS from '@/store/scope/types/actions';
import { UPDATE_NAV } from '@/store/profile/actions';

import AppLoading from '@/components/common/AppLoading';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal';

import AppTypeForm from './AppTypeForm';
import RoleItem from './roles/RoleItem';
import TypeItem from './TypeItem';
import CreateRoleModal from './roles/CreateRoleModal';
import UpdateRoleModal from './roles/UpdateRoleModal';
import RegistriesList from './registries/RegistriesList';

export default {
    name: 'AppTypeInfo',
    props: ['appID', 'typeID'],
    components: {
        AppLoading,
        AppTypeForm,
        RegistriesList,
        RoleItem,
        TypeItem,
        RemoveConfirmModal,
        CreateRoleModal,
        UpdateRoleModal,
    },

    data() {
        return {
            isLoading: false,
            selectedRole: {},
            errorMsg: null,
        };
    },

    computed: {
        ...mapState('scope/types', {
            appType: 'selected',
        }),
    },

    watch: {
        appID() { this.reload(); },
        typeID() { this.reload(); },
    },
    created() { this.reload(); },

    methods: {
        ...mapActions('scope/types', {
            fetchScopeType: ACTIONS.SELECT_TYPE,
            updateScopeType: ACTIONS.UPDATE_TYPE,
            removeScopeType: ACTIONS.REMOVE_TYPE,
            createScopeRole: ACTIONS.CREATE_ROLE,
            updateScopeRole: ACTIONS.UPDATE_ROLE,
            removeScopeRole: ACTIONS.REMOVE_ROLE,
        }),
        ...mapActions('profile', { updateNav: UPDATE_NAV }),

        reload() {
            this.isLoading = true;
            this.fetchScopeType(this.typeID).then(() => {
                this.isLoading = false;
                this.updateNav({ appID: this.appID });
            }).catch(() => {
                this.$router.push({ name: 'app-types', params: { appID: this.appID } });
            });
        },

        submitUpdateType(payload) {
            this.updateScopeType({
                typeID: this.typeID,
                payload,
            }).then(() => {
                this.errorMsg = null;
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        submitRemoveType() {
            this.removeScopeType({ appID: this.appID, id: this.typeID }).finally(() => {
                this.$router.push({ name: 'app-types', params: { appID: this.appID } });
            });
        },

        submitCreateRole(payload) {
            this.createScopeRole({ typeID: this.typeID, payload }).then(() => {
                this.$refs.createRoleModal.hide();
            }).catch((err) => {
                this.$refs.createRoleModal.showError(err.message);
            });
        },

        submitUpdateRole(payload) {
            this.updateScopeRole({
                typeID: this.appType.id,
                id: this.selectedRole.id,
                payload,
            }).then(() => {
                this.$refs.editRoleModal.hide();
            }).catch((err) => {
                this.$refs.editRoleModal.showError(err.message);
            });
        },

        submitRemoveRole() {
            this.removeScopeRole({
                typeID: this.appType.id,
                id: this.selectedRole.id,
            }).finally(() => {
                this.$refs.removeRoleModal.hide();
            });
        },

        openRemoveRoleModal(role) {
            this.selectedRole = role;
            this.$refs.removeRoleModal.open();
        },

        openEditRoleModal(role) {
            this.selectedRole = role;
            this.$refs.editRoleModal.open(role);
        },
    },
};
