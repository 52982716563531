<template>
    <div class="content text-left" v-if="attr != null">
        <div v-if="attr.type == 'string'">
            <input class="form-input" type="text" v-model="value" >
        </div>
        <div v-else-if="attr.type == 'text'">
            <textarea class="form-input" type="text" rows="3" v-model="value" />
        </div>
        <div v-else-if="attr.type == 'enum'">
            <select class="form-select" v-model="value">
                <option v-for="item in list" :key="item.code"
                    :value="item.code">{{item.text}}</option>
            </select>
        </div>
        <div v-else-if="attr.type == 'masked'">
            <imask-input class="form-input"
                v-model="value"
                :mask="mask" :unmask="false"
                @accept="is_completed = false"
                @complete="is_completed = true" />
        </div>
        <div v-else class="has-error">
            <div class="form-input-hint">
                Данный тип аттрибута не поддерживается
            </div>
        </div>
    </div>
</template>

<script>

import { IMaskComponent } from 'vue-imask';

export default {
    name: 'AttributeValue',
    props: {
        attr: {
            type: Object,
        },
    },

    components: {
        ImaskInput: IMaskComponent,
    },

    data() {
        return {
            value: null,
            mask: null,
            list: [],
            is_completed: false,
        };
    },

    computed: {
        hasValue() {
            if (this.attr == null) {
                return false;
            }

            if ((this.attr.type === 'text' || this.attr.type === 'string')
                && (this.value == null || this.value.length === 0)) {
                return false;
            }
            if (this.attr.type === 'enum' && this.value == null) {
                return false;
            }
            if (this.attr.type === 'masked' && !this.is_completed) {
                return false;
            }
            return true;
        },

        getValue() { return this.value; },
    },

    mounted() {
        this.reload();
    },

    watch: {
        attr() {
            this.reload();
        },
    },

    methods: {
        reload() {
            this.value = null;
            this.mask = null;
            this.list = [];
            this.is_completed = false;

            if (this.attr != null) {
                if (this.attr.type === 'enum') {
                    this.list = this.attr.options ? JSON.parse(this.attr.options) : [];
                }
                if (this.attr.type === 'masked') {
                    this.mask = this.attr.options;
                }
            }
        },
    },
};
</script>
