<template>
    <div>
        <app-loading v-if="isLoading" />
        <div v-else class="col-12 container">
            <div class="h3 text-center">Реестры</div>
            <div class="form-group columns text-right">
                <div class="column">
                    <search-box v-model="filterSearch" @change="setFilterSearch(filterSearch)" />
                </div>
            </div>
            <div v-if="!isEmpty">
                <div class="text-left">
                    <div :key="item.id" v-for="item in registriesList">
                        <router-link custom v-slot="{ navigate }"
                            :to="{ name: 'registry-info', params: {
                                appID, typeID, registryID: item.id
                            }}" >
                            <div @click="navigate" >
                                <registry-ref class="c-hand" :registry="item" />
                            </div>
                        </router-link>
                    </div>
                </div>
                <table-pagination
                    :page="registriesPage.page" :last="registriesPage.count"
                    @changed="changePage($event)"/>
            </div>
            <div class="columns p-2">
                <table-page-size class="col-ml-auto" @changed="changeSize($event)"
                    :size="registriesPage.size" :total="registriesPage.total"
                >реестров</table-page-size>
            </div>
            <div class="container py-2">
                <router-link custom v-slot="{ navigate }"
                    :to="{name: 'create-registry', params: { appID, typeID }}" >
                    <button type="button" class="btn btn-primary"
                        @click="navigate">Создать реестр</button>
                </router-link>
            </div>
            <error-msg :error="registriesError" />
        </div>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import * as A from '@/store/scope/types/registries/actions';

import ErrorMsg from '@/components/common/ErrorMsg';
import AppLoading from '@/components/common/AppLoading';
import TablePageSize from '@/components/common/TablePageSize';
import TablePagination from '@/components/common/TablePagination';
import SearchBox from '@/components/common/SearchBox';

import RegistryRef from './RegistryRef';

export default {
    name: 'TypeRegistriesList',
    props: ['appID', 'typeID'],
    components: {
        AppLoading, RegistryRef, ErrorMsg, TablePageSize, TablePagination, SearchBox,
    },

    data() {
        return {
            isLoading: false,
            filterSearch: null,
        };
    },

    created() {
        this.reloadList();
    },

    watch: {
        typeID: 'reloadList',
        registriesFilters: 'reloadFilters',
    },

    computed: {
        ...mapState('scope/types/registries', {
            registriesList: 'list',
            registriesPage: 'pages',
            registriesFilters: 'filters',
            registriesAvailable: 'available',
            registriesError: 'error',
        }),
        isEmpty() { return this.registriesList == null || this.registriesList.length === 0; },
    },

    methods: {
        ...mapActions('scope/types/registries', {
            updateList: A.UPDATE_LIST,
            changePage: A.CHANGE_PAGE,
            changeSize: A.CHANGE_SIZE,
            setFilterSearch: A.FILTER_SEARCH,
        }),

        reloadFilters(filters) {
            this.filterSearch = filters.search || null;
        },

        reloadList() {
            this.isLoading = true;
            this.updateList({ typeID: this.typeID }).finally(() => {
                this.reloadFilters(this.registriesFilters);
                this.isLoading = false;
            });
        },
    },
};
</script>
