<template>
    <div>
        <div class="columns">
            <div class="column">
                <i>{{task.execute | formatDate }} выполнено
                    {{task.executor | userFullName }}</i>
            </div>
            <div class="column col-auto">
                <slot />
            </div>
        </div>

        <task-value v-for="value in task.values" :key="value.id"
            :taskID="task.id" :value="value" />
    </div>
</template>

<script>
import TaskValue from '@/components/tasks/TaskValue';

export default {
    name: 'ProcessTask',
    props: ['task'],
    components: { TaskValue },
};
</script>
