<template>
    <!-- Модальное окно выбора пользователя системы -->
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container">
            <div class="modal-header">
                <a @click="hide()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title text-center h5">
                    Укажите пользователя для формы {{form.name}}
                </div>
            </div>
            <div class="modal-body content text-left">
                <app-scope-select ref="scopes"
                    :root="root"
                    @select-scope="updateScope($event)"
                />

                <app-users-select ref="users"
                    v-if="scope != null"
                    :listUsers="listUsers"
                    @select-user="selectUser($event)"
                />
            </div>
        </form>
    </div>

</template>

<style lang="scss" scoped>
.modal {
    align-items: flex-start;
    .modal-container {
        overflow: visible;
        .modal-body {
            padding: 0 0.8rem;
            overflow: visible;
        }
    }
}
</style>

<script>

import Api from '@/api';

import AppScopeSelect from '@/components/common/AppScopeSelect';
import AppUsersSelect from '@/components/common/AppUsersSelect';

export default {
    name: 'SelectUserModal',
    props: {
        root: {
            type: Object,
        },
    },
    components: { AppScopeSelect, AppUsersSelect },

    data() {
        return {
            show: false,
            form: {},
            scope: null,
        };
    },

    computed: {
        listUsers() {
            return opts => Api.scopeUsers(this.scope.id, opts);
        },
    },

    methods: {
        open(form) {
            this.form = form;
            this.scope = this.root;
            this.show = true;
            this.$refs.scopes.reload();
            this.$refs.users.reload();
        },

        hide() {
            this.show = false;
        },

        updateScope(scope) {
            if (this.scope == null || this.scope.id !== scope.id) {
                this.scope = scope;
                if (this.$refs.users) {
                    this.$refs.users.reload();
                }
            }
        },

        selectUser(user) {
            this.$emit('select-user', { scope: this.scope, user, form: this.form });
        },
    },
};

</script>
