<template>
    <div class="panel col-10 col-sm-12">
         <div class="panel-header">
            <div class="panel-title h3">Создание нового реестра</div>
        </div>
        <div class="panel-nav">
            <registry-form v-slot="form">
                <div class="form-group">
                    <button class="btn btn-primary" type="button"
                        :disabled="!form.isValid"
                        @click="submit(form.payload)"
                        >Создать</button>
                </div>
            </registry-form>
            <error-msg :error="error" />
        </div>
    </div>
</template>

<script>

import { mapActions } from 'vuex';
import { CREATE_REGISTRY } from '@/store/scope/types/registries/actions';

import ErrorMsg from '@/components/common/ErrorMsg';
import RegistryForm from './RegistryForm';

export default {
    name: 'CreateNewRegistry',
    props: ['appID', 'typeID'],
    components: { ErrorMsg, RegistryForm },

    data() {
        return {
            error: null,
        };
    },

    methods: {
        ...mapActions('scope/types/registries', {
            createRegistry: CREATE_REGISTRY,
        }),

        submit(payload) {
            const { appID, typeID } = this;
            this.createRegistry({ typeID: this.typeID, payload }).then(() => {
                this.$router.push({ name: 'app-type-info', params: { appID, typeID } });
            }).catch((err) => {
                this.error = err;
            });
        },
    },
};
</script>
