<template>
    <div class="modal" :class="{ 'active': show }">
        <div @click="hide()" class="modal-overlay" aria-label="Close"></div>
        <form class="modal-container" @submit.prevent="hide()">
            <div class="modal-header">
                <a @click="cancel()" class="btn btn-clear float-right" aria-label="Close"></a>
                <div class="modal-title h5">Комментарий к элементу формы</div>
            </div>
            <div class="modal-body">
                <div class="content text-left" v-if="attr != null">
                    <textarea class="form-input" type="text" rows="3"
                        v-model="attr.description" />
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn mx-1" type="button" @click="cancel()">Закрыть</button>
                <button class="btn btn-primary" type="submit">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    name: 'FormDescriptionModal',
    data() {
        return {
            show: false,
            attr: null,
            description: null,
        };
    },

    methods: {
        open(attr) {
            this.attr = attr;
            this.description = attr.description;
            this.show = true;
        },

        cancel() {
            this.attr.description = this.description;
            this.hide();
        },

        hide() {
            this.attr = null;
            this.description = null;
            this.show = false;
        },
    },
};

</script>
