<template>
    <div class="history timeline bg-gray text-left px-2">
        <div class="timeline-item">
            <div class="timeline-left">
                <span class="timeline-icon"></span>
            </div>
            <div class="timeline-content">
                <i>{{process.create | formatDate}} создано {{process.initiator | userFullName }}</i>

                <div v-for="link in process.links" :key="link.id" class="text-right">
                    <slot name="link" v-bind:link="link"></slot>
                </div>
            </div>
        </div>

        <details class="timeline-item accordion" :key="idx" v-for="(step, idx) in process.history">
            <summary class="accordion-header timeline-left">
                <span class="timeline-icon icon-lg">
                    <i class="icon icon-arrow-right"></i>
                </span>
                <b class="c-hand">{{step.state.state}}</b>
            </summary>
            <div class="accordion-body">
                <div :key="task.id" v-for="task in step.tasks">
                    <slot name="task" v-bind:task="task" />
                </div>
                <div v-if="step.state.subprocess">
                    <slot name="taskProcesses" v-bind:state="step.state" />
                </div>
            </div>
        </details>

        <div class="timeline-item timeline-end">
            <div class="timeline-left">
                <span class="timeline-icon icon-lg">
                    <i class="icon icon-check"></i>
                </span>
            </div>
            <div class="timeline-content">
                <div class="tile-content">
                    <b class="m-0">{{process.state}}</b>
                    <div class="columns">
                        <div class="column col-12"
                            :key="task.id" v-for="task in process.tasks">
                            <slot name="task" v-bind:task="task" />
                        </div>
                    </div>

                    <div v-if="process.processes">
                        <i>{{process.processes.active}} из {{process.processes.all}}
                            процессов в работе</i>
                        <slot name="subProcesses" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="@/styles/processHistory.scss" ></style>

<script>

export default {
    name: 'ProcessHistory',
    props: { process: Object },
};
</script>
