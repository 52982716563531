import { mapActions, mapGetters, mapState } from 'vuex';

import * as A from '@/store/scope/schemas/actions';
import { UPDATE_NAV } from '@/store/profile/actions';

import Api from '@/api';

import ErrorMsg from '@/components/common/ErrorMsg';
import AppLoading from '@/components/common/AppLoading';
import UploadButton from '@/components/common/UploadButton';
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal';

import SchemaForm from './SchemaForm';
import SelectRoleModal from './SelectRoleModal';
import SelectUserModal from './SelectUserModal';
import SelectTypeModal from './SelectTypeModal';
import FormDescriptionModal from './FormDescriptionModal';
import FormLabelConfigModal from './FormLabelConfigModal';

function byId(a, b) {
    if (a.id < b.id) { return -1; }
    if (a.id > b.id) { return 1; }
    return 0;
}

function byScope(a, b) {
    if (a.scope.id < b.scope.id) { return -1; }
    if (a.scope.id > b.scope.id) { return 1; }
    if (a.role.id < b.role.id) { return -1; }
    if (a.role.id > b.role.id) { return 1; }
    return 0;
}

function filterByID(arr, a) {
    return arr.filter(e => e.id === a.id);
}

function filterByRole(arr, r) {
    return arr.filter(e => e.scope.id === r.scope.id
        && e.role.id === r.role.id);
}

function filterByExecType(arr, t) {
    return arr.filter((e) => {
        if (e.type.id !== t.type.id) return false;
        if (e.filterAttr && t.filterAttr) {
            return e.filterAttr.id === t.filterAttr.id
                && e.filterValue === t.filterValue;
        }
        return !e.filterAttr && !t.filterAttr;
    });
}

function cloneObj(obj) {
    return obj != null ? Object.assign({}, obj) : null;
}

function cloneSchema(schema) {
    return {
        id: schema.id,
        name: schema.name,
        create_date: schema.create_date,
        processes_count: schema.processes_count,
        all_processes_count: schema.all_processes_count,
        forms: schema.forms.map(s => ({
            id: s.id,
            name: s.name,
            is_start: s.is_start,
            is_finish: s.is_finish,
            custom_name_id: !s.custom_name_id ? null : s.custom_name_id,
            accounts_count: s.accounts_count,
            items: s.items.map(cloneObj),
            actions: s.actions.map(cloneObj),
            subprocess: !s.subprocess ? null : {
                type: s.subprocess.type,
                schema: cloneObj(s.subprocess.schema),
                exec_types: s.subprocess.exec_types.map(t => ({
                    type: cloneObj(t.type),
                    filterAttr: cloneObj(t.filterAttr),
                    filterValue: t.filterValue,
                })),
            },
            exec_accounts: s.exec_accounts.map(cloneObj).sort(byId),
            exec_roles: s.exec_roles.map(r => ({
                scope: cloneObj(r.scope),
                role: cloneObj(r.role),
            })).sort(byScope),
        })).sort(byId),
    };
}

function schemaEquals(s1, s2) {
    if (s1 === null && s2 === null) {
        return true;
    }
    if (s1 === null || s2 === null) {
        return false;
    }

    if (s1.name !== s2.name) {
        return false;
    }

    if (s1.forms.length !== s2.forms.length) {
        return false;
    }

    for (let i = 0; i < s1.forms.length; i += 1) {
        const ss1 = s1.forms[i];
        const finded = filterByID(s2.forms, ss1);
        if (finded.length !== 1) {
            return false;
        }
        const ss2 = finded[0];

        if (ss1.name !== ss2.name) {
            return false;
        }

        if (ss1.custom_name_id && ss2.custom_name_id) {
            if (ss1.custom_name_id !== ss2.custom_name_id) {
                return false;
            }
        } else if (ss1.custom_name_id || ss2.custom_name_id) {
            return false;
        }

        if (ss1.items.length !== ss2.items.length) {
            return false;
        }

        for (let j = 0; j < ss1.items.length; j += 1) {
            const i1 = ss1.items[j];
            const i2 = ss2.items[j];
            if (i1.id !== i2.id
                || i1.name !== i2.name
                || i1.description !== i2.description
                || i1.is_required !== i2.is_required
                || i1.is_report !== i2.is_report
                || i1.options !== i2.options) {
                return false;
            }
        }

        if (ss1.actions.length !== ss2.actions.length) {
            return false;
        }

        for (let j = 0; j < ss1.actions.length; j += 1) {
            const a1 = ss1.actions[j];
            const a2 = ss2.actions[j];
            if (a1.id !== a2.id || a1.name !== a2.name) {
                return false;
            }
        }

        if (ss1.subprocess !== null && ss2.subprocess !== null) {
            const types1 = ss1.subprocess.exec_types;
            const types2 = ss2.subprocess.exec_types;
            if (types1.length !== types2.length) {
                return false;
            }
            for (let j = 0; j < types1.length; j += 1) {
                if (filterByExecType(types2, types1[j]).length !== 1) {
                    return false;
                }
            }
        }

        if (ss1.exec_accounts.length !== ss2.exec_accounts.length) {
            return false;
        }
        if (ss1.exec_roles.length !== ss2.exec_roles.length) {
            return false;
        }

        for (let j = 0; j < ss1.exec_accounts.length; j += 1) {
            if (filterByID(ss2.exec_accounts, ss1.exec_accounts[j]).length !== 1) {
                return false;
            }
        }

        for (let j = 0; j < ss1.exec_roles.length; j += 1) {
            if (filterByRole(ss2.exec_roles, ss1.exec_roles[j]).length !== 1) {
                return false;
            }
        }
    }

    return true;
}

export default {
    name: 'ProcessSchemaInfo',
    props: ['appID', 'schemaID'],
    components: {
        AppLoading,
        RemoveConfirmModal,
        UploadButton,
        SchemaForm,
        SelectRoleModal,
        SelectUserModal,
        SelectTypeModal,
        FormDescriptionModal,
        FormLabelConfigModal,
        ErrorMsg,
    },

    data() {
        return {
            isLoading: false,
            info: {},
            cloneFromID: null,
            errorMsg: null,
        };
    },

    computed: {
        ...mapState('scope/schemas', {
            schemasList: 'list',
            schemasError: 'error',
            schema: 'selected',
        }),

        ...mapState('profile', ['profile']),
        ...mapGetters('profile', ['isDeveloper']),

        name() { return this.schema != null ? this.schema.name : ''; },
        canRemove() { return this.schema != null && this.schema.processes_count === 0; },
        rootScope() { return this.profile && this.profile.scope; },
        changed() { return !schemaEquals(this.info, this.schema); },
        reportURL() { return Api.processSchemaReportURL(this.schema.id); },
    },

    watch: {
        appID() { this.reload(); },
        schemaID() { this.reload(); },
    },
    created() { this.reload(); },

    methods: {
        ...mapActions('scope/schemas', {
            reloadSchemas: A.UPDATE_LIST,
            fetchSchema: A.SELECT_SCHEMA,
            removeSchema: A.REMOVE_SCHEMA,
            updateSchema: A.UPDATE_SCHEMA,
            cloneProcesses: A.CLONE_PROCESSES,
        }),

        ...mapActions('profile', { updateNav: UPDATE_NAV }),

        reload() {
            this.isLoading = true;
            this.fetchSchema(this.schemaID).then(() => {
                this.isLoading = false;
                this.updateNav({ appID: this.appID });
                this.info = cloneSchema(this.schema);
            }).catch(() => {
                this.$router.push({ name: 'app-schemas', params: { appID: this.appID } });
            });
        },

        submitRemoveProcess() {
            this.removeSchema(this.schemaID).finally(() => {
                this.$refs.removeModal.hide();
                this.$router.push({ name: 'app-schemas', params: { appID: this.appID } });
            });
        },

        submitUpdateProcess() {
            this.$refs.uploadButton.stop();
            if (this.info.name == null || this.info.name.length === 0) {
                this.errorMsg = 'Название схемы не может быть пустым';
                return;
            }
            if (this.info.forms.filter(s => s.name == null || s.name.length === 0).length > 0) {
                this.errorMsg = 'Название формы не может быть пустым';
                return;
            }
            const payload = {
                name: this.info.name,
                forms: this.info.forms.map(s => ({
                    id: s.id,
                    name: s.name,
                    custom_name_id: s.custom_name_id,
                    items: s.items.map(i => ({
                        id: i.id,
                        name: i.name,
                        options: i.options,
                        is_report: i.is_report,
                        description: i.description,
                    })),
                    actions: s.actions.map(a => ({
                        id: a.id,
                        name: a.name,
                    })),
                    exec_accounts: s.exec_accounts.map(a => a.id),
                    exec_roles: s.exec_roles.map(r => ({
                        scopeID: r.scope.id,
                        roleID: r.role.id,
                    })),
                    exec_types: !s.subprocess ? [] : s.subprocess.exec_types.map(t => ({
                        typeID: t.type.id,
                        filterAttrID: t.filterAttr != null ? t.filterAttr.id : null,
                        filterValue: t.filterAttr != null ? t.filterValue : null,
                    })),
                })),
            };

            this.updateSchema({ id: this.schema.id, ref: payload }).then(() => {
                this.info = cloneSchema(this.schema);
                this.errorMsg = null;
            }).catch((err) => {
                this.errorMsg = err.message;
            });
        },

        addFormExecRole({
            role, scope, form, scopeOnly,
        }) {
            this.info.forms.filter(s => s.id === form.id).forEach((s) => {
                if (filterByRole(s.exec_roles, { role, scope, scopeOnly }).length === 0) {
                    s.exec_roles.push({ scope, role, scopeOnly });
                }
            });
            this.$refs.selectRoleModal.hide();
        },

        removeFormExecRole({ role, form }) {
            filterByID(this.info.forms, form).forEach((s) => {
                filterByRole(s.exec_roles, role).forEach((r) => {
                    const idx = s.exec_roles.indexOf(r);
                    s.exec_roles.splice(idx, 1);
                });
            });
        },

        addFormExecUser({ user, form }) {
            filterByID(this.info.forms, form).forEach((s) => {
                if (filterByID(s.exec_accounts, user).length === 0) {
                    s.exec_accounts.push(user);
                }
            });
            this.$refs.selectUserModal.hide();
        },

        removeFormExecUser({ user, form }) {
            filterByID(this.info.forms, form).forEach((s) => {
                filterByID(s.exec_accounts, user).forEach((a) => {
                    const idx = s.exec_accounts.indexOf(a);
                    s.exec_accounts.splice(idx, 1);
                });
            });
        },

        addSchemaExecType({ form, schema, type }) {
            filterByID(this.info.forms, form).forEach((s) => {
                if (s.subprocess && s.subprocess.schema.id === schema.id) {
                    if (filterByExecType(s.subprocess.exec_types, type).length === 0) {
                        s.subprocess.exec_types.push(type);
                    }
                }
            });
            this.$refs.selectTypeModal.hide();
        },

        removeSchemaExecType({ form, schema, type }) {
            filterByID(this.info.forms, form).forEach((s) => {
                if (s.subprocess && s.subprocess.schema.id === schema.id) {
                    filterByExecType(s.subprocess.exec_types, type).forEach((a) => {
                        const idx = s.subprocess.exec_types.indexOf(a);
                        s.subprocess.exec_types.splice(idx, 1);
                    });
                }
            });
        },

        openConfigModal(item) {
            if (item.type === 'label') {
                this.$refs.labelConfigModal.open(item);
            }
        },

        submitReloadList() {
            this.reloadSchemas({ appID: this.appID });
        },

        submitCloneProcesses() {
            if (this.cloneFromID) {
                this.cloneProcesses({
                    schemaID: this.schemaID,
                    sourceID: this.cloneFromID,
                });
            }
        },
    },
};
