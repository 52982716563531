<template>
    <div class="panel">
        <div class="panel-header">
            <div class="h4">{{process.name}}</div>
        </div>

        <div class="panel-nav">
            <slot name="nav"></slot>
        </div>

        <div class="panel-body text-left">
            <div v-if="process.base">
                <div v-if="process.base.base">
                    <process-history :process="process.base.base">
                        <template v-slot:link="{ link }">
                            <a target="_blank" rel="noopener noreferrer"
                                :href="baseLinkUrl(link)"
                                class="btn btn-link">{{link.name}}</a>
                        </template>

                        <template v-slot:task="{ task }">
                            <process-task :task="task" />
                        </template>
                    </process-history>
                </div>

                <process-history :process="process.base">
                    <template v-slot:link="{ link }">
                        <a target="_blank" rel="noopener noreferrer"
                            :href="baseLinkUrl(link)"
                            class="btn btn-link">{{link.name}}</a>
                    </template>

                    <template v-slot:task="{ task }">
                        <process-task :task="task" />
                    </template>
                </process-history>
            </div>
            <process-history :process="process">
                <template v-slot:link="{ link }">
                    <a target="_blank" rel="noopener noreferrer"
                        :href="linkUrl(link)"
                        class="btn btn-link">{{link.name}}</a>
                </template>

                <template v-slot:taskProcesses="{ state }">
                    <process-sub-list :processID="process.pid" :stateID="state.id" />
                </template>

                <template v-slot:subProcesses>
                    <process-sub-list :processID="process.pid" :stateID="process.id" />
                </template>

                <template v-slot:task="{ task }">
                    <slot name="task" v-bind:task="task" />
                </template>
            </process-history>
        </div>

        <div class="panel-footer text-left">
            <div v-if="!process.is_finish">
                <div class="text-left h5">Назначены</div>
                <div v-if="process.has_executors" class="text-left">
                    <div class="toast my-1" :key="user.id"
                        v-for="user in processInfo.executors">
                        Пользователь {{user | userFullName}}
                    </div>
                </div>
                <div v-else>
                    <div class="text-left">
                        <div class="toast my-1" :key="r.id"
                            v-for="r in process.exec_roles">
                            Все пользователи с ролью {{r.role.name}}
                            в разделе {{r.scope.name}}
                        </div>
                    </div>
                    <div class="text-left">
                        <div class="toast my-1" :key="user.id"
                            v-for="user in process.exec_accounts">
                            Пользователь {{user | userFullName}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.panel-body {
    padding: 0;
}
</style>

<script>

import Api from '@/api';

import ProcessHistory from './ProcessHistory';
import ProcessSubList from './ProcessSubList';
import ProcessTask from './ProcessTask';

export default {
    name: 'ProcessForm',
    components: { ProcessHistory, ProcessSubList, ProcessTask },
    props: {
        process: { type: Object },
    },

    methods: {
        linkUrl(link) {
            return Api.processLinkURL(this.process.pid, link.id);
        },
        baseLinkUrl(link) {
            return Api.processLinkURL(this.process.base.pid, link.id);
        },
    },
};
</script>
